// Body Block: Heading
// Follows the regular h2-h6 styles inside .article-body, with a few additional options for flexibility:
//
// - Style: adjust the look and layout without being dependent on its semantic element
//      - "standard-hed"
//      - "chapter-hed"
//      - "explanatory-hed"
// - Kicker: small text that appears above the heading
// - Semantic element: customize the HTML element being used (for SEO purposes)

.bb-heading {
    color: var(--color-text-hed);
    font-family: var(--fonts-sans);
    font-weight: 700;
    line-height: var(--line-height-2);
    margin-top: calc(1em * var(--line-height-2) * 1.5);
    margin-bottom: var(--vert-rhythm-sm);

    .bb-heading__kicker + & {
        margin-top: var(--spacing-1);
    }
}

.bb-heading--standard-hed {
    font-size: var(--scale3);
}

.bb-heading--chapter-hed {
    font-family: var(--fonts-hed);
    font-size: var(--scale3);
    font-weight: 900;
}

.bb-heading--explanatory-hed {
    font-size: var(--scale1);
}

.bb-heading__kicker {
    color: var(--color-text-hed);
    display: block;
    font-family: var(--fonts-sans);
    font-size: var(--scale-2);
    font-weight: 400;
    letter-spacing: 1px;
    line-height: var(--line-height-2);
    margin-top: calc(var(--scale2) * var(--line-height-2) * 1.5);
    text-transform: uppercase;
}

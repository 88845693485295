.story-river-item {
    @include breakpoint-max(lg) {
        display: static;
    }

    @include breakpoint-min(lg) {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-areas: "dateline dateline art art hed hed hed hed hed hed byline byline";
        grid-template-columns: repeat(12, 1fr);
    }

    @include breakpoint-min(xl) {
        grid-template-areas: "dateline dateline art art hed hed hed hed hed hed hed blank byline byline";
        grid-template-columns: repeat(14, 1fr);
    }
}

.story-river-item__dateline {
    color: var(--color-text-meta);
    display: inline;
    font-family: var(--fonts-sans);
    font-size: var(--scale-2);
    line-height: var(--line-height-1);

    @include breakpoint-min(lg) {
        grid-area: dateline;
    }
}
.story-river-item__art {
    display: block;

    @include breakpoint-max(lg) {
        float: right;
        margin-left: var(--gutter);
    }

    @include breakpoint-range(xs, sm) {
        width: 6rem;
    }
    @include breakpoint-range(sm, md) {
        width: colspan(2, 6);
    }
    @include breakpoint-range(md, lg) {
        width: colspan(3, 8);
    }

    @include breakpoint-min(lg) {
        grid-area: art;
    }
}

.story-river-item__hed {
    color: var(--color-text-hed);
    display: block;
    font-family: var(--fonts-hed);
    font-weight: 700;
    line-height: var(--line-height-2);

    @include breakpoint-max(md) {
        font-size: var(--scale0);
    }
    @include breakpoint-range(md, lg) {
        font-size: var(--scale1);
    }
    @include breakpoint-min(lg) {
        font-size: var(--scale0);
        grid-area: hed;
    }
}

.story-river-item__meta {
    @include breakpoint-max(lg) {
        display: block;
        line-height: var(--line-height-3);
        margin-top: var(--spacing-2);
    }
    @include breakpoint-min(lg) {
        display: contents;
    }
}

.story-river-item__byline {
    color: var(--color-text-meta);
    display: inline;
    font-family: var(--fonts-sans);
    font-size: var(--scale-2);
    line-height: var(--line-height-1);

    @include breakpoint-min(lg) {
        grid-area: byline;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
    a:hover {
        color: var(--color-accent-50);
        text-decoration: underline;
    }
}

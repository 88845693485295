.bb-ad {
    @include set-light-dark(background-color, var(--gray-3), var(--gray-97));
    padding: var(--spacing2);
    position: relative;

    &::before {
        @include set-light-dark(color, var(--black), var(--white));
        content: "Advertisement";
        display: block;
        font-family: var(--fonts-sans);
        font-size: var(--scale-3);
        left: 50vw;
        letter-spacing: 1px;
        position: absolute;
        text-transform: uppercase;
        top: 0.6rem;
        transform: translateX(-50%);
    }
}

.htlad-inline-2 {
    min-height: 90px; // best-guess placeholder to prevent page jumps
    margin: 0 auto; // guard rails to prevent large (full-width) ads
    @include breakpoint-min(md) {
        max-width: 728px;
    }
    @include breakpoint-min(lg) {
        max-width: 970px;
    }
}

//----------------------------------------
// MOST READ
//----------------------------------------

.most-read__heading {
    color: var(--text-color-hed);
    display: block;
    font-family: var(--fonts-sans);
    font-size: var(--scale0);
    font-weight: 700;
}

.most-read-group {
    counter-reset: start-from 0; /* Initiate a counter */
    margin-top: var(--spacing-2);

    @include breakpoint-min(sm) {
        border-top: 1px solid var(--color-neutral-10);
    }
}

/* Layout */
.most-read-story {
    .story-card__series,
    .story-card__dek,
    .story-card__byline {
        display: none;
    }

    .story-card__hed {
        // color: var(--color-text-hed);
        // display: block;
        // font-size: inherit;
        // font-weight: 700;
        line-height: var(--line-height-2);
        padding-left: var(--scale0);
        text-indent: calc(var(--scale0) * -1);

        @include breakpoint-min(md) {
            padding-left: calc(colspan(1, 8) + var(--gutter));
            text-indent: 0;
        }

        &::before {
            color: var(--color-accent-50);
            display: inline-block;
            content: counter(start-from); /* Use the counter as content */
            font-family: var(--fonts-sans);
            font-feature-settings: "tnum" 1;
            font-size: var(--scale-1);
            text-indent: 0;
            width: var(--scale0);

            @include breakpoint-min(md) {
                display: block;
                font-size: var(--scale4);
                font-weight: 700;
                margin-left: calc(-1 * colspan(1, 8) - var(--gutter));
                text-align: right;
                text-indent: colspan(1, 8);
                width: colspan(1, 8);
                position: absolute;
            }
        }
    }
}

.most-read-story {
    counter-increment: start-from; /* Increment the counter by 1 */
    display: flex;

    @include breakpoint-max(sm) {
        border-top: 1px solid var(--color-neutral-10);
    }
}

// .most-read-story__link
.most-read-story {
    .story-card {
        align-self: stretch;
        border-top: 1px solid var(--color-neutral-10);
        color: inherit;
        display: block;
        padding: var(--spacing0) 0;
        text-decoration: none;
        width: 100%;
    }
    &:nth-child(1) .story-card {
        border-top: none;
    }
}

// for .lead-art
.most-read-story {
    .story-card .lead-art {
        display: block;
        margin-bottom: var(--spacing-1);

        @include breakpoint-max(md) {
            float: none;
            margin-left: 0;
            width: 100%;
        }

        @include breakpoint-min(md) {
            width: colspan(2, 8) !important;
            margin-left: var(--gutter);
            margin-bottom: 0;
        }
    }
}

// for .story-card__hed
.most-read-story {
    .story-card__hed {
        font-size: var(--scale0);
    }

    .story-card__hed {
        font-size: var(--scale2);

        @include breakpoint-min(sm) {
            font-size: var(--scale0);
            line-height: var(--line-height-2);
        }
    }

    .story-card__unit {
        display: none;
    }
}

.modal {
    background-color: var(--black);
    height: 100vh;
    left: 0;
    opacity: 1;
    overflow: scroll;
    position: fixed;
    top: 0;
    transition: opacity 0.75s linear;
    width: 100vw;
    z-index: 1000;
}

// Only needed to handle flash on page load in Safari
@starting-style {
    .modal {
        opacity: 0;
    }
}

.modal.-js-hide {
    display: block !important;
    opacity: 0;
    pointer-events: none;
}

.modal__header {
    @include full-width;
    background-color: var(--black);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: var(--spacing0);
    position: sticky;
    top: 0;
}

// Donation Promo
// - For Engaged Users

.promo-donate-2 {
    @include breakpoint-max(md) {
        margin-bottom: 2.5rem !important;
        margin-left: calc(#{$spacing1} * -1) !important;
    }
}

.promo-donate-2__max-width-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 36rem;
    padding: var(--spacing0) 0;
    position: relative;

    @include breakpoint-max(md) {
        padding: var(--spacing1);
    }
}

.promo-donate-2__bg {
    @include set-light-dark(fill, var(--warm-10), var(--warm-80));
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    svg {
        height: 100%;
    }
}

.promo-donate-2__text {
    font-family: var(--fonts-sans);
    font-size: var(--scale0);
    font-weight: 700;
    line-height: var(--line-height-2);

    @include breakpoint-max(md) {
        font-size: var(--scale1);
    }
}

.promo-donate-2__btn {
    @include set-light-dark(mix-blend-mode, multiply, lighten);
    margin-top: var(--spacing0);

    @include breakpoint-max(md) {
        margin: var(--spacing0) 0 -2.5rem 0;
        width: colspan(2, 4);
    }
}

// Modifier: full
// - puts elements in a row, increase spacing

.promo-donate-2--full {
    .promo-donate-2__max-width-wrapper {
        @include breakpoint-min(lg) {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: var(--spacing2);
        }
    }

    .promo-donate-2__text {
        @include breakpoint-min(lg) {
            line-height: var(--line-height-1);
        }
    }

    .promo-donate-2__btn {
        @include breakpoint-min(lg) {
            height: auto;
            margin: 0 0 0 var(--spacing2);
            min-width: 8rem;
            width: auto;
        }
    }
}

.event {
    @include breakpoint-min(sm) {
        width: colspan(4, 6);
    }
    @include breakpoint-min(md) {
        width: colspan(6, 8);
    }
    @include breakpoint-min(lg) {
        width: colspan(8, 12);
    }
    @include breakpoint-min(xl) {
        width: colspan(8, 14);
    }
}

.event-metadata-wrapper {
    flex-wrap: wrap;
}

.event-actions-wrapper {
    align-items: center;
}

.event-more-info-btn {
    width: 10rem;
    @include breakpoint-min(md) {
        width: 15rem;
    }
    @include breakpoint-min(xl) {
        width: 20rem;
    }
}

.event-body {
    @include clearfix;

    // Keeps colors the same in dark mode for engagement event type
    // prettier-ignore
    .text-dark & {
        @include set-light-dark(--color-text-hed, var(--black), var(--black));
        @include set-light-dark(--color-text-body, var(--gray-90), var(--gray-90));
        @include set-light-dark(--color-text-supp, var(--gray-70), var(--gray-70));
        @include set-light-dark(--color-text-meta, var(--gray-40), var(--gray-40));
        color: var(--color-text-body);
    }

    --vert-margin: var(--spacing1);
    font-family: var(--fonts-sans);
    font-size: var(--scale0);
    margin-bottom: var(--spacing1);
    margin-top: var(--spacing1);

    @include breakpoint-min(lg) {
        margin-bottom: var(--spacing3);
        margin-top: var(--spacing3);
    }

    > p:not([class]) {
        margin-top: var(--vert-margin);
        margin-bottom: var(--vert-margin);
    }

    > *:not([class]) a,
    > .spaced-list a {
        // The first selector applies to links inside something that have no classes applied on it.
        // This means that links inside generated content from copy blocks will get this styling while avoiding buttons and other components.
        // We could also choose to wrap copy block content inside a `.bb-copy` div, and then use a selector like `> .bb-copy a` instead.
        // The second selector covers additional types of links that are missed by the first rule.

        &,
        &:visited {
            color: inherit;
            text-decoration: underline;
            text-decoration-color: inherit;
            text-decoration-thickness: 0.1em;
            text-underline-offset: 0.1em;

            &:focus,
            &:hover {
                text-decoration-color: currentColor;
            }
        }
    }

    > h2:not([class]),
    > h3:not([class]),
    > h4:not([class]),
    > h5:not([class]),
    > h6:not([class]) {
        font-family: var(--fonts-sans);
        font-weight: 700;
        line-height: var(--line-height-2);
        margin-top: calc(1em * var(--line-height-2) * 1.5);
    }
    > h2:not([class]) {
        font-size: var(--scale3);
    }
    > h3:not([class]) {
        font-size: var(--scale3);
    }
    > h4:not([class]) {
        font-size: var(--scale2);
    }
    > h5:not([class]) {
        font-size: var(--scale1);
    }
    > h6:not([class]) {
        font-size: var(--scale0);
    }

    ol:not([class]),
    ul:not([class]) {
        list-style-position: outside;
        margin: var(--vert-margin) 0;
        margin-left: 2em;
    }
    ol:not([class]) {
        list-style-type: decimal;
    }
    li:not([class]) ol:not([class]) {
        list-style-type: lower-alpha;
        margin-bottom: 0;
        margin-top: 0;
    }
    li:not([class]) li:not([class]) ol:not([class]) {
        list-style-type: lower-roman;
    }
    ul:not([class]) {
        list-style-type: disc;
    }
    li:not([class]) ul:not([class]) {
        list-style-type: circle;
        margin-bottom: 0;
        margin-top: 0;
    }
    li:not([class]) li:not([class]) ul:not([class]) {
        list-style-type: square;
    }
    .spaced-list li:not([class]) {
        margin: var(--vert-margin) 0;
    }

    > blockquote:not([class]) {
        @include set-light-dark(
            border-color,
            var(--color-accent-20),
            var(--color-accent-80)
        );
        border-left: var(--spacing-2) solid;
        font-family: var(--fonts-sans);
        margin: var(--vert-margin) 0;
        padding-left: calc(1em);
    }

    > hr:not([class]) {
        // prettier-ignore
        @include set-light-dark(background-color, var(--black), var(--black));
        // prettier-ignore
        .text-light & { @include set-light-dark(background-color, var(--color-accent-70), var(--color-accent-70)); }

        border: none;
        height: var(--spacing-1);
        margin: calc(var(--vert-margin) * 2) auto;
        width: colspan(3, 8);
    }

    > pre {
        margin: var(--vert-margin) 0;
    }
    code {
        @include set-light-dark(
            background-color,
            var(--gray-10),
            var(--gray-80)
        );
        border-radius: 0.25em;
        font-family: var(--fonts-mono);
        padding: 0.25em;
    }
    > pre code {
        display: block;
        padding: 2em;
        overflow-x: scroll;
    }
}

.event-supporter-wrapper {
    margin-bottom: var(--spacing3);
    margin-top: var(--spacing3);
}

.event-supporter-wrapper--float {
    border: 1px solid var(--color-text-hed);
    padding: var(--scale1);

    // prettier-ignore
    .text-light & { @include set-light-dark(border-color, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)); }
    // prettier-ignore
    .text-dark & { @include set-light-dark(border-color, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); }
}

.event-supporter__logo {
    height: 2.5rem;
    margin-bottom: var(--spacing0);
    width: auto;

    .text-light & {
        filter: grayscale(1) invert(1);
    }
}

.event-supporter__message {
    color: var(--color-text-body);
    font-size: var(--scale-2);
    font-weight: 700;

    a,
    a:visited {
        color: var(--color-text-meta);
        font-weight: 400;

        .text-dark & {
            mix-blend-mode: multiply;
        }

        .text-light & {
            mix-blend-mode: screen;
        }
    }
}

.event-signup__post-signup-prompt {
    background-color: var(--white);
    // border: 1px solid var(--white);
    border-radius: 3px;

    @include breakpoint-min(lg) {
        display: grid;
        grid-template-areas:
            "hed hed hed"
            "info info share";
        grid-template-columns: 2fr var(--spacing0) 1fr;
    }
}

.event-signup__post-signup-prompt-info-wrapper {
    color: var(--cool-90);
    padding: var(--spacing0);
    grid-area: info;
}

.event-signup__post-signup-prompt-share-wrapper {
    padding: var(--spacing0);
    grid-area: share;

    @include breakpoint-max(lg) {
        padding-top: 0;
    }
}

.event-signup__post-signup-prompt-hed {
    background-color: var(--color-accent-10);
    border-radius: 3px 3px 0 0;
    color: var(--color-accent-70);
    font-size: var(--scale0);
    font-weight: 700;
    grid-area: hed;
    padding: var(--spacing-1) var(--spacing0);
}

.event-signup__post-signup-prompt-label {
    font-size: var(--scale-2);
    color: var(--gray-50);
    display: block;
    margin-bottom: var(--spacing-1);
    text-transform: uppercase;
}

.event-signup__post-signup-prompt-metadata-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--spacing-2);

    > * + * {
        margin-left: var(--spacing1);
    }
}

.event-signup__post-signup-prompt-name {
    display: block;
    line-height: var(--line-height-2);
}

.event-signup__post-signup-prompt-date,
.event-signup__post-signup-prompt-time,
.event-signup__post-signup-prompt-location {
    font-size: var(--scale-2);
}

.event-follow-up {
}

.event-follow-up__header {
    font-size: var(--scale2);
    font-weight: 700;
    line-height: var(--line-height-2);
    margin-top: calc(1em * var(--line-height-2) * 1.5);
    margin-bottom: var(--spacing0);
}

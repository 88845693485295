//----------------------------------------
// TOPICS BAND
//----------------------------------------

.topics-band {
    clear: both;
}

.topics-band__heading {
    color: var(--text-color-hed);
    display: block;
    font-family: var(--fonts-sans);
    font-size: var(--scale0);
    font-weight: 700;
    line-height: var(--line-height-3);

    border-bottom: 1px solid var(--color-neutral-10);
    margin-bottom: var(--spacing0);
    padding-bottom: var(--spacing-1);

    @include breakpoint-min(xl) {
        margin-left: auto;
        margin-right: auto;
        width: colspan(12, 14);
    }

    .icon {
        position: absolute;
        transform: translateX(0.2em);
    }
}

/* Topics Band Layout */
.topics-band-group {
    @include breakpoint-min(xl) {
        margin-left: auto;
        margin-right: auto;
        width: colspan(12, 14);
    }

    .story-card {
        @include breakpoint-max(sm) {
            flex: 0 0 colspan(3, 4);
        }

        @include breakpoint-range(sm, md) {
            flex: 0 0 colspan(4, 6);
        }

        @include breakpoint-range(md, lg) {
            flex: 0 0 colspan(3, 8);
        }

        @include breakpoint-min(lg) {
            flex: 0 0 colspan(4, 12);
        }
    }

    .story-card .lead-art {
        float: none;
        margin-left: 0;
        width: 100%;
    }

    .story-card__dek,
    .story-card__byline {
        display: none;
    }
}

/* Scroll snapping */
.topics-band-group .story-card {
    scroll-snap-align: start;
    scroll-margin: var(--page-margin);

    @include breakpoint-min(xl) {
        scroll-margin: 0;
    }
}

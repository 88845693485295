.bb-image-set {
    > .multi *:nth-child(1n + 2) {
        margin-left: var(--gutter);
    }

    @include breakpoint-range(xs, sm) {
        &.wide-xs {
            > .multi {
                display: block;
            }
            > .multi *:nth-child(1n + 2) {
                margin: var(--gutter) 0 0 0;
                width: 100%;
            }
        }
    }

    @include breakpoint-range(xs, md) {
        &.wide-sm {
            > .multi {
                display: block;
            }
            > .multi *:nth-child(1n + 2) {
                margin: var(--gutter) 0 0 0;
                width: 100%;
            }
        }
    }

    @include breakpoint-range(xs, lg) {
        &.wide-md {
            > .multi {
                display: block;
            }
            > .multi *:nth-child(1n + 2) {
                margin: var(--gutter) 0 0 0;
                width: 100%;
            }
        }
    }
}

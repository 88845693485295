/* Overall page layout */
.entry-type-topic-listing {
    @include breakpoint-max(lg) {
        .topics-listing-section-impact + .topics-listing-section-series {
            margin-top: var(--spacing2);
        }
    }

    @include breakpoint-min(lg) {
        .topics-listing-section-impact {
            width: colspan(4, 12);
        }
        .topics-listing-section-impact--wide {
            min-width: colspan(8, 12);
        }
        .topics-listing-section-series {
            max-width: colspan(8, 12);
            min-width: colspan(4, 12);
        }
        .promo-series {
            width: 14rem !important;
        }
    }

    @include breakpoint-min(xl) {
        .topics-listing-section-impact {
            width: colspan(4, 14);
        }
        .topics-listing-section-impact--wide {
            min-width: colspan(10, 14);
        }
        .topics-listing-section-series {
            max-width: colspan(10, 14);
            min-width: colspan(4, 14);
        }
    }
}

.topics-listing-header {
    margin: 4rem auto var(--spacing3) auto;

    @include breakpoint-min(xl) {
        width: colspan(12, 14);
    }
}

.topics-listing-header__parent {
    color: var(--color-text-hed);
    font-family: var(--fonts-sans);
    font-size: var(--scale0);
    font-weight: 700;
    line-height: var(--line-height-3);
    text-align: center;
}

/* Subtopics Nav */

.entry-type-topic-listing {
    .subnav {
        margin-bottom: var(--spacing3);
    }
}

.subtopics-nav__label {
    padding: var(--spacing-1) 0;

    background-color: var(--color-bg);
    color: var(--color-neutral-30);
    font-family: var(--fonts-sans);
    font-size: var(--scale-2);
    font-weight: 600;
    line-height: var(--line-height-3);
    text-transform: uppercase;
    transform: translateY(1px);
    white-space: nowrap;
}

.subtopics-nav__subtopic {
    border-radius: 4px;
    padding: var(--spacing-1);

    background-color: var(--color-bg);
    color: var(--color-text-hed);
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    font-weight: 400;
    line-height: var(--line-height-3);
    text-decoration: none;
    white-space: nowrap;

    transition: all 0.15s linear;

    &:hover {
        background-color: var(--color-neutral-10);
    }
}

/* Header */

.topics-listing-header__name {
    color: var(--color-text-hed);
    font-family: var(--fonts-hed);
    font-size: var(--scale5);
    font-weight: 900;
    line-height: var(--line-height-3);
    text-align: center;
}

.topics-listing-header__tagline {
    color: var(--color-neutral-50);
    font-family: var(--fonts-hed);
    font-size: var(--scale2);
    font-weight: 400;
    line-height: var(--line-height-2);
    text-align: center;
}

.topics-listing-introduction {
    color: var(--color-neutral-50);
    font-family: var(--fonts-sans);
    font-size: var(--scale0);
    font-weight: 400;
    line-height: var(--line-height0);
    letter-spacing: -0.02rem;

    margin: var(--spacing1) auto 0 auto;

    @include breakpoint-min(xl) {
        width: colspan(10, 14);
    }

    a {
        color: inherit;
        text-decoration: underline;
    }
}

/* Featured Stories */

.topics-listing-section-featured-stories {
    @include breakpoint-max(lg) {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        > .story-card + .story-card {
            border-top: 1px solid var(--color-neutral-10);
            padding-top: 1rem;
        }

        > .story-card {
            .lead-art {
                float: none;
                margin-left: 0;
                width: 100%;
            }
            .story-card__hed {
                font-size: var(--scale2);
                line-height: var(--line-height-2);
            }
        }
    }
    @include breakpoint-min(lg) {
        margin: 0 auto;
        width: colspan(12, 12);

        display: grid;
        column-gap: var(--gutter);
        row-gap: 1.2rem;
        grid-template-areas:
            "one one one"
            "two two three"
            "two two four";
        grid-template-columns: repeat(3, 1fr);

        > .story-card:nth-of-type(1),
        > .story-card:nth-of-type(2) {
            .lead-art {
                margin-left: 1rem;
            }
            .story-card__hed {
                line-height: var(--line-height-2);
            }
        }

        > .story-card:nth-of-type(1) {
            border-bottom: 1px solid var(--color-neutral-10);
            padding-bottom: 1rem;

            .lead-art {
                width: colspan(8, 12);
            }
            .story-card__hed {
                font-size: var(--scale2);
            }
        }

        > .story-card:nth-of-type(2) {
            .lead-art {
                width: colspan(4, 8);
            }
            .story-card__hed {
                font-size: var(--scale1);
            }
        }

        > .story-card:nth-of-type(3),
        > .story-card:nth-of-type(4) {
            .story-card__hed {
                font-size: var(--scale-1);
            }
            .lead-art,
            .story-card__dek {
                display: none;
            }
        }

        > .story-card:nth-of-type(3) {
            border-bottom: 1px solid var(--color-neutral-10);
            padding-bottom: 1rem;
        }
    }
    @include breakpoint-min(xl) {
        width: colspan(10, 14);
        grid-template-areas:
            "one one one one one one one   one   one   one"
            "two two two two two two three three three three"
            "two two two two two two four  four  four  four";
        grid-template-columns: repeat(10, 1fr);

        > .story-card:nth-of-type(1) {
            .lead-art {
                width: colspan(7, 10);
            }
        }

        > .story-card:nth-of-type(2) {
            .lead-art {
                width: colspan(3, 6);
            }
        }
    }

    > .story-card:nth-of-type(1) {
        grid-area: one;
    }
    > .story-card:nth-of-type(2) {
        grid-area: two;
    }
    > .story-card:nth-of-type(3) {
        grid-area: three;
    }
    > .story-card:nth-of-type(4) {
        grid-area: four;
    }
}

/* Staff */

.topics-listing-featured-staff__section-hed {
    border-bottom: 1px solid var(--color-neutral-10);
    padding-bottom: var(--scale-2);

    font-family: var(--fonts-sans);
    font-size: var(--scale0);
    font-weight: 700;
    line-height: var(--line-height-3);

    /* For horizontally-scrolling sections, pin the header on the left edge */
    position: sticky;
    left: 0;

    .js-scrollable-overflow--is-overflowing & {
        width: 100%;
    }
}

.topics-listing-featured-staff__group-hed {
    color: var(--color-neutral-50);
    font-family: var(--fonts-sans);
    font-size: var(--scale-2);
    font-weight: 700;
    text-transform: uppercase;

    /* For horizontally-scrolling sections, pin the header on the left edge */
    position: sticky;
    left: 0;
}

.topics-listing-featured-staff__photo {
    @include set-light-dark(background-color, var(--gray-3), var(--gray-97));
    border-radius: 50%;
    height: 5rem;
    width: 5rem;
}

.topics-listing-featured-staff__name {
    font-family: var(--fonts-sans);
    font-size: var(--scale-2);
    font-weight: 400;
    line-height: var(--line-height-2);
    text-align: center;
}

/* Impact */

.topics-listing-section-impact {
    .story-card__hed {
        font-size: var(--scale-1);
    }

    .lead-art,
    .story-card__series,
    .story-card__dek,
    .story-card__unit {
        display: none;
    }
}

/* Series */

// .topics-listing-section-series {
//     position: relative;
// }

.topics-listing-section-series__content {
    @include breakpoint-max(lg) {
        max-height: 150px;
        max-height: calc(8em + var(--spacing0));
        flex-wrap: wrap;
    }
}

.topics-listing-section-series {
    .promo-series {
        margin: 0 !important;
        flex: 0 0 auto;
        width: 18rem;
    }
}

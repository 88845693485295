.bb-tweet {
    .twitter-tweet-rendered {
        margin: 0 auto !important;
    }

    .twitter-tweet:not(.twitter-tweet-rendered) {
        // Fallback
        border: 1px solid var(--cool-10);
        border-radius: 3px;
        color: var(--color-text-meta);
        font-family: var(--fonts-sans);
        font-size: var(--scale-2);
        padding: var(--spacing1);

        @include breakpoint-min(lg) {
            margin: 0 var(--spacing2);
        }

        > p {
            color: var(--color-text-body);
            font-size: var(--scale-1);
            margin-bottom: var(--spacing-1);
        }

        a {
            color: inherit;
        }
    }
}

.bb-aside {
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);

    *:first-of-type {
        margin-top: var(--spacing-2);
    }

    p {
        line-height: var(--line-height-1);
        margin-top: var(--spacing0);
        margin-bottom: var(--spacing0);
    }

    a,
    a:visited {
        color: inherit;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 700;
        line-height: var(--line-height-2);
    }
    h2 {
        font-size: var(--scale0);
    }
    h3 {
        font-size: var(--scale0);
    }
    h4 {
        font-size: var(--scale-1);
    }
    h5 {
        font-size: var(--scale-2);
    }
    h6 {
        font-size: var(--scale-3);
    }

    // List styles are covered from more general rules defined in .article-body -
    // if we choose to rescope those styles, remember to add them here.
}

.bb-aside__note {
    font-size: var(--scale-2);
}

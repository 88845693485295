@include grid(page-recirc);
/* @include grid(page-recirc, overlay); */

.page-recirc__content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing3);
    margin-top: var(--spacing3);

    @include breakpoint-min(lg) {
        display: grid;
        gap: var(--gutter);
        grid-template-columns: repeat(12, 1fr);
        margin-top: 6rem;
    }
    @include breakpoint-min(xl) {
        margin-left: auto;
        margin-right: auto;
        width: colspan(12, 14);
    }
}

.page-recirc__intro-module {
    @include breakpoint-min(lg) {
        grid-column: 9 / span 4;
        grid-row: 1 / span 2;
    }
    @include breakpoint-min(xl) {
        grid-column: 10 / span 3;
    }
}

.page-recirc__topics-band {
    @include breakpoint-min(lg) {
        grid-column: 1 / span 8;
        grid-row: 1;
    }
}

.page-recirc__most-read {
    @include breakpoint-min(lg) {
        grid-column: 1 / span 8;
        grid-row: 1;

        .page-recirc__topics-band + & {
            grid-row: 2;
        }
    }
}

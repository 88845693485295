// Article Body
// Sets basic variables and sizes

.article-body {
    @include clearfix;
    font-size: var(--scale0);
    margin-top: var(--spacing1);

    @include breakpoint-min(lg) {
        margin-top: var(--spacing3);
    }

    > p:not([class]) {
        font-feature-settings: "onum" 1;
        margin-top: var(--vert-rhythm-sm);
        margin-bottom: var(--vert-rhythm-sm);
    }

    > *:not([class]) a,
    > .spaced-list a {
        // The first selector applies to links inside something that have no classes applied on it.
        // This means that links inside generated content from copy blocks will get this styling while avoiding buttons and other components.
        // We could also choose to wrap copy block content inside a `.bb-copy` div, and then use a selector like `> .bb-copy a` instead.
        // The second selector covers additional types of links that are missed by the first rule.

        &,
        &:visited {
            color: var(--color-accent-70);
            text-decoration: underline;
            text-decoration-color: var(--color-accent-30);
            text-decoration-thickness: 0.1em;
            text-underline-offset: 0.1em;

            &:focus,
            &:hover {
                text-decoration-color: currentColor;
            }

            @media print {
                color: inherit;
                text-decoration-color: currentColor;
            }
        }
    }

    > h2:not([class]),
    > h3:not([class]),
    > h4:not([class]),
    > h5:not([class]),
    > h6:not([class]) {
        font-family: var(--fonts-sans);
        font-weight: 700;
        line-height: var(--line-height-2);
        margin-top: calc(1em * var(--line-height-2) * 1.5);
        margin-bottom: var(--vert-rhythm-sm);
    }
    > h2:not([class]) {
        font-size: var(--scale3);
    }
    > h3:not([class]) {
        font-size: var(--scale3);
    }
    > h4:not([class]) {
        font-size: var(--scale2);
    }
    > h5:not([class]) {
        font-size: var(--scale1);
    }
    > h6:not([class]) {
        font-size: var(--scale0);
    }

    ol,
    ul {
        list-style-position: outside;
        margin: var(--vert-rhythm-sm) 0;
        margin-left: 2em;
    }
    ol {
        list-style-type: decimal;
    }
    li ol {
        list-style-type: lower-alpha;
        margin-bottom: 0;
        margin-top: 0;
    }
    li li ol {
        list-style-type: lower-roman;
    }
    ul {
        list-style-type: disc;
    }
    li ul {
        list-style-type: circle;
        margin-bottom: 0;
        margin-top: 0;
    }
    li li ul {
        list-style-type: square;
    }
    .spaced-list li {
        margin: var(--vert-rhythm-sm) 0;
    }

    table {
        border-collapse: collapse;
        @include set-light-dark(border-color, var(--cool-20), var(--cool-70));
        border-style: solid;
        border-width: 1px;
        font-family: var(--fonts-sans);
        font-size: var(--scale-1);
        line-height: var(--line-height-1);
        width: 100%;
    }
    th {
        @include set-light-dark(background, var(--cool-10), var(--cool-80));
        font-weight: 700;
    }
    th,
    td {
        @include set-light-dark(border-color, var(--cool-20), var(--cool-70));
        border-style: solid;
        border-width: 1px;
        padding: var(--spacing-2);
        text-align: left;
        &.numeric {
            text-align: right;
            font-feature-settings: "tnum" 1;
        }
    }

    // Matches margins ($mar) in _column-settings.scss
    > .full .attribution {
        margin-left: var(--spacing1);
        margin-right: var(--spacing1);

        @include breakpoint-min(md) {
            margin-left: var(--spacing3);
            margin-right: var(--spacing3);
        }
    }

    > blockquote:not([class]) {
        @include set-light-dark(
            border-color,
            var(--color-accent-20),
            var(--color-accent-80)
        );
        border-left: var(--spacing-2) solid;
        font-family: var(--fonts-sans);
        line-height: var(--line-height-1);
        margin: var(--vert-rhythm-sm) 0;
        padding-left: calc(1em);

        // Allow elements like paragraphs to be nested inside with spacing
        // (We don’t use flexbox + gap because we want blockquote text to be
        // able to wrap around floating elements.)
        > * + * {
            margin-top: var(--vert-rhythm-sm);
        }
    }

    > hr:not([class]) {
        @include set-light-dark(
            background-color,
            var(--color-accent-30),
            var(--color-accent-70)
        );
        border: none;
        height: var(--spacing-1);
        margin: var(--vert-rhythm-lg) auto;
        width: colspan(3, 8);

        @media print {
            border-bottom: 2pt solid currentColor;
            height: 0;
        }
    }

    :is(.bb-image, .bb-image-set, .bb-video, .bb-video-self-hosted)
        + :is(.bb-image, .bb-image-set, .bb-video, .bb-video-self-hosted) {
        margin-top: calc(var(--gutter) - var(--vert-rhythm-lg));
    }

    :is(.bb-image, .bb-image-set, .bb-video, .bb-video-self-hosted)
        + .wide-xs:is(.bb-image, .bb-image-set, .bb-video, .bb-video-self-hosted) {
        @include breakpoint-max(sm) {
            margin-top: calc(
                var(--gutter) - 1.728rem
            ) !important; // The .wide- classes set their own distinct margins (1.728rem) so we need to override with this.
        }
    }

    :is(.bb-image, .bb-image-set, .bb-video, .bb-video-self-hosted)
        + .wide-sm:is(.bb-image, .bb-image-set, .bb-video, .bb-video-self-hosted) {
        @include breakpoint-max(md) {
            margin-top: calc(
                var(--gutter) - 1.728rem
            ) !important; // The .wide- classes set their own distinct margins (1.728rem) so we need to override with this.
        }
    }

    :is(.bb-image, .bb-image-set, .bb-video, .bb-video-self-hosted)
        + .wide-md:is(.bb-image, .bb-image-set, .bb-video, .bb-video-self-hosted) {
        @include breakpoint-max(lg) {
            margin-top: calc(
                var(--gutter) - 1.728rem
            ) !important; // The .wide- classes set their own distinct margins (1.728rem) so we need to override with this.
        }
    }

    > pre {
        margin: var(--vert-rhythm-sm) 0;
    }
    code {
        @include set-light-dark(
            background-color,
            var(--gray-10),
            var(--gray-80)
        );
        border-radius: 0.25em;
        font-family: var(--fonts-mono);
        padding: 0.25em;
    }
    > pre code {
        display: block;
        padding: 2em;
        overflow-x: scroll;
    }
}

// Notes

.article-body__note {
    font-family: var(--fonts-serif);
    font-feature-settings: "onum" 1;
    font-size: var(--scale-1);
    font-style: italic;

    p {
        margin-bottom: var(--spacing0);
        margin-top: var(--spacing0);
    }

    ul li {
        list-style: disc;
        margin-bottom: var(--spacing-1);
        margin-top: var(--spacing-1);
    }

    a,
    a:visited {
        color: inherit;
    }
}

.article-body__note--co-publish,
.article-body__note--related-story,
.article-body__note--story-update,
.article-body__note--tips-callout,
.article-body__note--newsletter {
    max-width: 66ch;

    @media print {
        max-width: none;
    }
}

.article-body__note--correction {
    font-family: var(--fonts-serif);
    font-size: var(--scale-1);
    font-style: italic;
}

.article-body__correction-label {
    @include set-light-dark(
        background-color,
        var(--yellow-10),
        var(--yellow-80)
    );
    font-family: var(--fonts-sans);
    font-size: var(--scale-2);
    font-style: normal;
    font-weight: 700;
    line-height: var(--line-height-1);
    display: block;
    padding: var(--spacing-2);
    text-transform: uppercase;

    @include breakpoint-min(lg) {
        padding: var(--spacing-1);
    }
}

.article-body__note--contributor-line {
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    font-style: normal;
}

.article-body__note--content-warning {
    color: var(--color-text-hed);
    display: flex;
    font-family: var(--fonts-sans);
    font-style: normal;
    font-weight: 700;
    line-height: var(--line-height-1);

    &::before {
        background-image: url("data:image/svg+xml;utf8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M10.29%203.86001L1.82%2018C1.64537%2018.3024%201.55297%2018.6453%201.55199%2018.9945C1.55101%2019.3437%201.64149%2019.6871%201.81443%2019.9905C1.98736%2020.2939%202.23673%2020.5468%202.53771%2020.7239C2.83869%2020.901%203.1808%2020.9962%203.53%2021H20.47C20.8192%2020.9962%2021.1613%2020.901%2021.4623%2020.7239C21.7633%2020.5468%2022.0126%2020.2939%2022.1856%2019.9905C22.3585%2019.6871%2022.449%2019.3437%2022.448%2018.9945C22.447%2018.6453%2022.3546%2018.3024%2022.18%2018L13.71%203.86001C13.5317%203.56611%2013.2807%203.32313%2012.9812%203.15449C12.6817%202.98585%2012.3437%202.89726%2012%202.89726C11.6563%202.89726%2011.3183%202.98585%2011.0188%203.15449C10.7193%203.32313%2010.4683%203.56611%2010.29%203.86001ZM12%208C12.5523%208%2013%208.44772%2013%209V13C13%2013.5523%2012.5523%2014%2012%2014C11.4477%2014%2011%2013.5523%2011%2013V9C11%208.44772%2011.4477%208%2012%208ZM12%2016C11.4477%2016%2011%2016.4477%2011%2017C11%2017.5523%2011.4477%2018%2012%2018H12.01C12.5623%2018%2013.01%2017.5523%2013.01%2017C13.01%2016.4477%2012.5623%2016%2012.01%2016H12Z%22%20fill%3D%22%23DA352B%22%2F%3E%0A%3C%2Fsvg%3E%0A");
        background-repeat: no-repeat;
        background-size: 100%;
        content: "";
        flex-shrink: 0;
        height: 2em;
        margin: 0.9em 0.8em 0.5em 0;
        width: 2em;

        @media print {
            display: none;
        }
    }
}

// Top Notes

// Bottom Notes

.article-body__bottom-notes {
    .article-body__correction-label {
        @include breakpoint-max(lg) {
            display: inline-block;
            float: none;
            line-height: var(--line-height-3);
            margin-bottom: 0 !important;
            width: auto !important;
        }

        @include breakpoint-min(lg) {
            margin-top: -0.3em !important;
            text-align: right;
        }

        // Only show the correction label once
        .article-body__correction-note + .article-body__correction-note & {
            display: none;
        }
    }

    .article-body__note--contributor-line::before {
        background-color: var(--color-text-hed);
        content: "";
        display: block;
        height: 1px;
        margin: var(--spacing2) 0 var(--spacing1);
        width: 8rem;
    }
}

// Highlights

.highlights {
    background-color: var(--gray-3);
    font-family: var(--fonts-sans);
    padding: var(--spacing1) var(--spacing0) var(--spacing0);
    margin-top: var(--vert-rhythm-sm);
    margin-bottom: var(--vert-rhythm-sm);
    text-wrap: pretty;

    @include breakpoint-min(sm) {
        padding: 1.6rem 1.4rem 1.2rem;
    }

    html.force-dark-mode & {
        background: var(--cool-80);
    }
    @media (prefers-color-scheme: dark) {
        html:not(.force-light-mode) & {
            background: var(--cool-80);
        }
    }
}

.highlights__heading {
    font-size: var(--scale0);
    font-weight: 700;
    line-height: var(--line-height-2);
    color: var(--color-text-hed);
    margin-bottom: 1rem;
}

.highlights__subheading {
    font-weight: 500;
}

.highlights__list {
    margin: var(--spacing0) 0.6rem 0.6rem !important;
    padding: 0;
}

.highlights__highlight {
    font-size: var(--scale-1);
    line-height: var(--line-height0);
    font-weight: 400;
    color: var(--color-text-body);
    &:not(:last-child) {
        margin-bottom: 0.4rem;
    }
}

.highlights__disclaimer {
    font-size: var(--scale-2);
    color: var(--color-text-meta);
    line-height: var(--line-height-1);
    position: relative;

    html.force-dark-mode & {
        color: var(--cool-40);
    }
    @media (prefers-color-scheme: dark) {
        html:not(.force-light-mode) & {
            color: var(--cool-40);
        }
    }
}

.contains-highlights {
    @include breakpoint-range(md, xl) {
        .promo-series {
            float: none !important;
            margin-left: 0 !important;
            width: 100% !important;
        }
        .promo-series__art {
            margin-bottom: 0;
            height: auto;
        }
    }

    .promo-series__header {
        display: flex;

        @include breakpoint-max(xl) {
            flex-direction: row;
            align-items: center;
        }

        @include breakpoint-min(xl) {
            flex-direction: column;
        }
    }

    .promo-series__art {
        display: block;
        @include breakpoint-max(xl) {
            height: 4em;
            margin-right: var(--spacing0);
            width: auto;
        }

        @include breakpoint-min(xl) {
            margin-bottom: var(--spacing0);
        }
    }
    .promo-donate-2__bg {
        html.force-dark-mode & {
            fill: var(--cool-80);
        }
        @media (prefers-color-scheme: dark) {
            html:not(.force-light-mode) & {
                fill: var(--cool-80);
            }
        }
    }

    .promo-series__introduction {
        display: none;
    }
}

@use "sass:map";

.bb-callout {
    --callout-module-height: 8rem;
    --callout-overlay-height: 6rem;
    position: relative;

    @include breakpoint-min(lg) {
        --callout-module-height: 10rem;
        --callout-overlay-height: 6rem;
    }
}

.bb-callout--inactive {
    border: 1px solid var(--color-neutral-10);
    border-radius: 4px;
    padding: var(--spacing1);
    padding-bottom: 0;
}

.bb-callout__title {
    color: var(--blue-50);
    font-family: var(--fonts-sans);
    font-size: var(--scale0);
    font-weight: 700;
    line-height: var(--line-height-2);
    margin-bottom: var(--spacing-1);
    margin-top: calc(var(--spacing2) * -1);
    transform: translateY(-0.5rem);
    text-align: center;
}

.bb-callout__wrapper {
    overflow: hidden;
    position: relative; // This is necessary to prevent a bug where overflow hidden doesn’t work (the Screendoor form still takes up its height on page).
    transition: height 1s ease-in;
}

.bb-callout--js-collapsed__wrapper {
    height: var(--callout-module-height) !important;
}

// Pulling colors from SCSS because we need to apply transparency to them. The rgba() function is incompatible with CSS variables.

$colors-cool: map.get($colors-neutral, "cool");
$colors-cool-light: map.get($colors-cool, "10");
$colors-cool-dark: map.get($colors-cool, "80");

.bb-callout__overlay {
    @include set-light-dark(
        --overlay-color-0,
        rgba($colors-cool-light, 0),
        rgba($colors-cool-dark, 0)
    );
    @include set-light-dark(
        --overlay-color-1,
        rgba($colors-cool-light, 1),
        rgba($colors-cool-dark, 1)
    );
    background: linear-gradient(
        to bottom,
        var(--overlay-color-0) 0%,
        var(--overlay-color-1) 90%
    );
    bottom: 0;
    content: "";
    display: block;
    height: var(--callout-overlay-height);
    left: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
}

.bb-callout__expand-button {
    // Right now size classes add top/bottom margin
    margin-top: calc(var(--spacing2) * -1);
    margin-bottom: 0;
    transform: translateY(50%);
}

.bb-callout .fr_form .fr_bottom {
    @include set-light-dark(background-color, var(--cool-20), var(--cool-70));
}

.bb-callout--inactive__message {
    background-color: var(--color-neutral-10);
    border-radius: 0 0 4px 4px;
    padding: var(--spacing0) var(--spacing1);
    margin: 0 calc(-1 * var(--spacing1));

    > p {
        color: var(--color-neutral-70);
        font-family: var(--fonts-sans);
        font-size: var(--scale-2);
        line-height: var(--line-height-1);
    }

    > p a {
        color: inherit;
    }
}

// .bb-callout .fr_form .fr_page [type="email"],
// .bb-callout .fr_form .fr_page [type="search"],
// .bb-callout .fr_form .fr_page [type="text"],
// .bb-callout .fr_form .fr_page .fr_field_wrapper textarea {
//     @include set-light-dark(background-color, var(--cool-10), var(--cool-80));
// }

//
// SCREENDOOR FORM STYLES
//

.fr_form {
    @include clearfix;
    @include set-light-dark(background-color, var(--cool-10), var(--cool-80));
    color: var(--color-text-hed);
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    padding: var(--spacing2);
}

// PATCH: override for inline styles
.fr_form .fr_page * {
    line-height: inherit;
}

// Field styles
.fr_form .fr_page [type="email"],
.fr_form .fr_page [type="search"],
.fr_form .fr_page [type="text"],
.fr_form .fr_page .fr_field_wrapper textarea {
    display: block;
    max-width: inherit;
    padding: var(--spacing-1);
    width: 100%;
}

.fr_form [type="email"],
.fr_form [type="search"],
.fr_form [type="text"] {
    margin-bottom: var(--spacing0);
}

.fr_response_field .fr_add_file label {
    line-height: var(--line-height-3);
}

.fr_form abbr.fr_required {
    text-decoration: none;
}

// Button styles
.fr_form .fr_button {
    @include set-light-dark(color, var(--white), var(--black));
    background: var(--color-accent-70);
    border-radius: 4px;
    border: none;
    display: inline-block;
    font-size: inherit;
    font-weight: 700;
    padding: var(--spacing-1);
    text-transform: uppercase;

    &:focus,
    &:hover {
        @include set-light-dark(color, var(--white), var(--black));
        background-color: var(--color-accent-50);
        border: none;
    }
}

input[data-rv-show] {
    margin-top: 0.25em;
}

// Block-level styles
.fr_form .fr_response_field {
    margin-bottom: var(--spacing1);
    max-width: inherit;
}

.fr_response_field .fr_option,
.fr_response_field label {
    margin-bottom: var(--spacing-1);
}

.fr_form .fr_text {
    font-size: inherit;
}

.fr_form .fr_error_alert_bar {
    margin-bottom: var(--spacing0);

    & a,
    & a:focus,
    & a:hover {
        // There is default Screendoor styling doing this already but it gets overriden by article link styles
        color: var(--white);
        border-bottom: 2px solid #fff;
        // Remove default Screendoor underline in favor of border
        text-decoration: none;
    }
}

.fr_response_field_section_break {
    margin-top: var(--spacing2);

    &:first-child {
        margin-top: 0;
    }
}

.fr_form hr {
    @include set-light-dark(
        background,
        var(--white) !important,
        var(--black) !important
    );
    border: none !important;
    height: 1px !important;
    margin: var(--spacing2) 0 !important;
}

// Labels / text styles
.fr_response_field label {
    font-weight: 700;
}

.fr_form .fr_response_field .fr_other_option,
.fr_form .fr_response_field label {
    font-size: inherit;
}

.fr_form .fr_response_field .fr_option,
.fr_form .fr_response_field .fr_option label {
    font-weight: normal;
}

.fr_form .fr_other_option label {
    display: block;
}

.fr_description {
    @include set-light-dark(
        color,
        var(--color-text-supp),
        var(--color-text-supp)
    );
    font-size: var(--scale-2);
    font-style: italic;
}

.fr_form h3 {
    font-weight: 700;
    margin-bottom: var(--spacing0);
    text-transform: uppercase;
}

.fr_form .fr_bottom {
    @include set-light-dark(background-color, var(--cool-10), var(--cool-80));
    padding: colspan(p, 4);
    padding: var(--spacing-1);
}

// .fr_option contains a set of choices, including radio buttons and (presumably) checkboxes
.fr_response_field .fr_option {
    margin-left: var(--spacing1);
    position: relative;
}

.fr_response_field .fr_option [type="radio"],
.fr_response_field .fr_option [type="checkbox"] {
    float: left;
    margin-left: calc(var(--spacing1) * -1);
    margin-top: var(--spacing-2);
}

// “Powered by Citybase” message
.fr_form + small {
    background-color: inherit !important;
    display: block !important;
    font-family: var(--fonts-sans);
    font-size: var(--scale-2);
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: right;

    a {
        color: inherit;
    }
}

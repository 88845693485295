.rich-byline-group {
    margin: var(--spacing3) 0;
}

.rich-byline {
    @include set-light-dark(border-color, var(--cool-10), var(--cool-80));
    align-items: center;
    border-top-width: 1px;
    border-style: solid;
    display: flex;
    flex-direction: row-reverse;
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    line-height: var(--line-height-1);
    padding-top: var(--spacing0);

    + .rich-byline {
        margin-top: var(--spacing0);
    }

    a {
        color: inherit;
        text-decoration: none;
        transition: 0.2s;
    }
}

.rich-byline__headshot {
    align-self: flex-start;
    border-radius: 2.5em;
    filter: grayscale(1);
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: var(--spacing-1);
    overflow: hidden;
    position: relative;
    width: 5em;
    @supports (mix-blend-mode: color) {
        &:after {
            background: var(--cool-90);
            bottom: 0;
            content: "";
            display: block;
            left: 0;
            mix-blend-mode: color;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

.rich-byline__info {
    width: 100%;
}

.rich-byline__name {
    font-weight: 700;

    &:focus,
    &:hover {
        color: var(--color-accent-50);
    }
}

.rich-byline__social-list {
    margin-bottom: 0.2rem;
    margin-top: -0.1rem;
}

.rich-byline__more {
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--spacing-1);
}

.rich-byline__more-item {
    margin-bottom: var(--spacing-2);
    margin-right: var(--spacing-1);

    .contact-tools button {
        // override the styles of this svelte component
        background: transparent;
        padding: 0;
        height: auto;
    }

    a,
    .contact-tools button {
        color: var(--color-neutral-40);
        display: block;
        font-size: 0.6rem;
        font-weight: 700;
        line-height: 1;
        text-decoration: underline;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;

        &:hover {
            opacity: 0.5;
        }
    }
}

.rich-byline__contact-prompt {
    background-color: var(--color-neutral-10);
    border-radius: 4px;
    line-height: var(--line-height-1);
    margin-top: var(--spacing-2);
    padding: var(--spacing-1);

    @include breakpoint-min(xl) {
        font-size: var(--scale-2);
    }
}

.rich-byline__contact-prompt-tail {
    @supports (top: anchor(var(--anchor))) {
        position: absolute;
        position-anchor: var(--anchor);
        top: anchor(var(--anchor) bottom);
        left: anchor(var(--anchor) center);
    }

    /* Note: this is not @supports not (top: anchor(var(--anchor))) because it does not work. @supports only evaluates valid and recognized property-value pairs. If a property or value is not part of the CSS specification (even as an experimental feature), it is considered invalid, and @supports ignores it entirely. */
    @supports not (position-anchor: var(--anchor)) {
        margin-left: 9rem; /* Hardcoded to the width of the "More Stories" link */
        margin-top: -0.25rem;
    }

    transform: translate(-50%, 0.33rem) rotate(45deg);
    width: 10px;
    height: 10px;
    background: var(--color-neutral-10);
}

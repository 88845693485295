// Newsletter Signup
// - For New Users

.promo-newsletter-signup-1 {
    --button-size: #{$spacing3};
    font-family: var(--fonts-sans);
}

.promo-newsletter-signup-1__form-wrapper {
    margin-bottom: var(--scale-3);
    margin-top: var(--scale-3);
    transition: opacity 1s ease-in;

    .-js-is-successful & {
        opacity: 0;
        pointer-events: none;
    }
}

.promo-newsletter-signup-1__form {
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: var(--scale-2);
    width: 100%;
}

.promo-newsletter-signup-1__input-wrapper {
    display: block;
    width: calc(100% - var(--button-size) - var(--spacing-1));
}

.promo-newsletter-signup-1__max-width-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 24rem;
}

.promo-newsletter-signup-1__bg {
    padding: var(--spacing1);
    margin-bottom: calc(var(--spacing1) * -1);
    margin-top: calc(var(--spacing1) * -1);
    z-index: -1;

    .aspect-1-1 {
        @include set-light-dark(
            background-color,
            var(--turquoise-10),
            var(--turquoise-90)
        );
        border-radius: 50%;

        margin-bottom: calc(
            -50% - var(--button-size) / 2 - var(--spacing2)
        ); /* spacing2 = height of layer 1 + space between layer 1 and layer 3 */
    }
}

.promo-newsletter-signup-1__logo-wrapper {
    height: var(--spacing1);
    transition: opacity 0.2s;

    .-js-has-error & {
        opacity: 0;
        pointer-events: none;
    }
}

.promo-newsletter-signup-1__logo svg {
    fill: var(--color-text-hed);
    width: auto;
    height: var(--spacing1);
    margin: 0 auto;
}

.promo-newsletter-signup-1__info-wrapper {
    height: calc(24rem / 2 - 2rem);
    margin-bottom: calc(var(--spacing1) * -1);
    order: 3;
    transition: opacity 1s;

    .-js-is-successful & {
        opacity: 0;
        pointer-events: none;
    }
}

.promo-newsletter-signup-1__name {
    font-size: var(--scale-1);
    font-weight: 700;
    line-height: var(--line-height-1);
}

.promo-newsletter-signup-1__desc {
    font-size: var(--scale-1);
    line-height: var(--line-height-1);
    padding-right: calc(var(--button-size) + var(--spacing-1));
}

.promo-newsletter-signup-1__submit {
    position: relative;
}

.promo-newsletter-signup-1__feedback {
    margin-bottom: 0;
    position: absolute;
    text-align: center;
    transform: translateY(calc(var(--button-size) * -0.5 - 100%));
}

.promo-newsletter-signup-1__post-signup-prompt {
    font-size: var(--scale-1);
    line-height: var(--line-height-1);
    margin-top: calc(var(--button-size) * -1 - var(--spacing-2));
    display: none;

    .-js-is-successful & {
        @include breakpoint-min(lg) {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }
}

.promo-newsletter-signup-1__prompt-button {
    flex-basis: 5rem;
    margin-top: var(--spacing0);
    min-width: 5rem;

    @include breakpoint-min(lg) {
        margin-top: 0;
        margin-left: var(--spacing0);
    }

    &.-js-is-copying {
        @include set-light-dark(background-color, var(--black), var(--white));
    }
}

// Modifier: small
// - hide logo, smaller type

.promo-newsletter-signup-1--small {
    .promo-newsletter-signup-1__logo svg {
        display: none;
    }

    .promo-newsletter-signup-1__name {
        font-size: var(--scale-2);
    }

    .promo-newsletter-signup-1__desc {
        font-size: var(--scale-2);
        padding-right: 0;
    }

    .promo-newsletter-signup-1__post-signup-prompt {
        font-size: var(--scale-2);
    }
}

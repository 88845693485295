// Newsletter Signup
// - For New Users

.promo-newsletter-signup-2 {
    --color-accent-50: var(--pink-50);
    --button-size: #{$spacing2};
    font-family: var(--fonts-sans);
}

.promo-newsletter-signup-2__form {
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: var(--scale-2);
    width: 100%;

    .-js-is-successful & {
        display: none;
    }
}

.promo-newsletter-signup-2__input-wrapper {
    display: block;
    width: 100%;
}

.promo-newsletter-signup-2__input-text {
    padding-right: var(--button-size);
}

.promo-newsletter-signup-2__max-width-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 36rem;
    padding: var(--spacing1);
    position: relative;

    @include breakpoint-max(sm) {
        // Fix issue with horizontal overscroll at smallest breakpoint, due to trapezoid skew increasing module width beyond margins.
        margin-left: calc((50vw - 50%) * -1);
        max-width: 100vw;
        overflow: hidden;
        width: 100vw;
    }
}

.promo-newsletter-signup-2__bg {
    @include set-light-dark(border-color, var(--black), var(--gray-70));
    border-left: 2px solid;
    border-top: 2px solid;
    display: block;
    height: var(--spacing3);
    margin-bottom: calc(var(--spacing2) * -1);
    margin-left: calc(var(--spacing0) * -1);
    width: 100%;

    &:before {
        // Parallelogram shape
        @include set-light-dark(
            background-color,
            var(--warm-10),
            var(--warm-80)
        );
        content: "";
        height: calc(100% + var(--spacing1));
        position: absolute;
        transform: skew(-18deg) translateY(calc(var(--spacing2) * -1));
        width: calc(100% - var(--spacing0));
        z-index: -1;
    }
}

.promo-newsletter-signup-2__header {
    margin-bottom: var(--scale-1);
    margin-top: 0;
}

.promo-newsletter-signup-2__logo svg {
    fill: var(--color-accent-50);
    height: var(--spacing1);
    margin-bottom: var(--spacing-1);
    width: auto;
}

.promo-newsletter-signup-2__info-wrapper {
    .-js-is-successful & {
        display: none;
    }
}

.promo-newsletter-signup-2__name {
    font-size: var(--scale-1);
    font-weight: 700;
    line-height: var(--line-height-1);
}

.promo-newsletter-signup-2__desc {
    font-size: var(--scale-1);
    line-height: var(--line-height-1);
}

.promo-newsletter-signup-2__post-signup-prompt {
    font-size: var(--scale-1);
    line-height: var(--line-height-1);
    display: none;

    .-js-is-successful & {
        display: block;
    }
}

.promo-newsletter-signup-2__prompt-text {
    margin-bottom: var(--spacing0);
}

.promo-newsletter-signup-2__prompt-button {
    width: 7rem;

    &.-js-is-copying {
        @include set-light-dark(background-color, var(--black), var(--white));
    }
}

// Modifier: small
// - hide logo, smaller type

.promo-newsletter-signup-2--small {
    .promo-newsletter-signup-2__logo svg {
        display: none;
    }

    .promo-newsletter-signup-2__name {
        font-size: var(--scale-2);
    }

    .promo-newsletter-signup-2__desc {
        font-size: var(--scale-2);
    }
}

// Modifier: full
// - centers header, adds more space, bigger type

.promo-newsletter-signup-2--full {
    .promo-newsletter-signup-2__header {
        @include breakpoint-min(lg) {
            margin-bottom: var(--scale3);
            margin-top: var(--scale2);
            text-align: center;
        }
    }

    .promo-newsletter-signup-2__logo svg {
        @include breakpoint-min(lg) {
            margin: 0 auto var(--spacing0);
        }
    }

    .promo-newsletter-signup-2__name {
        @include breakpoint-min(lg) {
            font-size: var(--scale1);
        }
    }

    .promo-newsletter-signup-2__desc {
        @include breakpoint-min(lg) {
            font-size: var(--scale0);
        }
    }
}

// Newsletter Signup
// - For Returning Users

.promo-newsletter-see-all-1 {
    --size: 10rem;
    align-items: center;
    display: flex;
    font-family: var(--fonts-sans);
    flex-direction: column;
    height: var(--size);
    justify-content: center;
}

.promo-newsletter-see-all-1__bg {
    @include set-light-dark(
        background-color,
        var(--turquoise-10),
        var(--turquoise-90)
    );
    border-radius: 50%;
    height: var(--size);
    position: absolute;
    width: var(--size);
    z-index: -1;
}

.promo-newsletter-see-all-1__layer1 {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    @include breakpoint-min(md) {
        max-width: calc(var(--size) * 1.5);
    }
}

.promo-newsletter-see-all-1__explore {
    font-size: var(--scale-2);
    line-height: var(--line-height-1);
}

.promo-newsletter-see-all-1__btn-see-all {
    @include set-light-dark(mix-blend-mode, multiply, lighten);
    flex-basis: 1;
    flex-grow: auto;
    flex-shrink: 0;
    margin-left: var(--spacing0);
    min-width: calc(var(--size) / 2);
}

// Modifier: small
// - puts the button below full-width

.promo-newsletter-see-all-1--small {
    .promo-newsletter-see-all-1__layer1 {
        @include breakpoint-min(md) {
            flex-direction: column;
        }
    }

    .promo-newsletter-see-all-1__btn-see-all {
        @include breakpoint-min(md) {
            align-self: flex-start;
            margin-left: 0;
            margin-top: var(--spacing0);
            width: 100%;
        }
    }
}

// Modifier: full
// - remove max-width so that it can span across

.promo-newsletter-see-all-1--full {
    .promo-newsletter-see-all-1__layer1 {
        max-width: none;
    }
}

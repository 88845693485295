.bb-callout-link {
    @include set-light-dark(background-color, var(--cool-10), var(--cool-80));

    border-radius: 4px;
    padding: var(--spacing1);
}

.callout-link__button {
    display: inline-block;
    font-size: var(--scale-1);
}

@use "sass:math";

//----------------------------------------
// V5 HOMEPAGE
//----------------------------------------
// Page layout
//----------------------------------------

.home__content {
    @include breakpoint-min(xs) {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-areas:
            "stories stories stories stories"
            "covid covid covid covid"
            "series series series series"
            "river river river river"
            "promos1 promos1 promos1 promos1"
            "promos2 promos2 promos2 promos2";
        grid-template-columns: repeat(4, 1fr);
    }
    @include breakpoint-min(sm) {
        grid-template-areas:
            "stories stories stories stories stories stories"
            "covid covid covid covid covid covid"
            "series series series series series series"
            "river river river river promos1 promos1"
            "promos2 promos2 promos2 promos2 promos1 promos1";
        grid-template-columns: repeat(6, 1fr);
    }
    @include breakpoint-min(md) {
        grid-template-areas:
            "stories stories stories stories stories stories stories stories"
            "covid covid covid covid covid covid covid covid"
            "series series series series series series series series"
            "promos1 promos1 river river river river promos2 promos2";
        grid-template-columns: repeat(8, 1fr);
    }
    @include breakpoint-min(lg) {
        grid-template-areas:
            "stories stories stories stories stories stories stories stories series series series series"
            "covid covid covid covid covid covid covid covid covid covid covid covid"
            "promos1 promos1 river river river river river river promos2 promos2 promos2 promos2";
        grid-template-columns: repeat(12, 1fr);
    }
    @include breakpoint-min(xl) {
        grid-template-areas:
            "stories stories stories stories stories stories stories stories stories series series series"
            "covid covid covid covid covid covid covid covid covid series series series"
            "promos1 promos1 river river river river river river promos2 promos2 promos2 promos2";
    }
}

.home__featured-stories {
    grid-area: stories;
}
.home__featured-series {
    grid-area: series;
}
.home__featured-covid {
    grid-area: covid;
}
.home__river {
    grid-area: river;
}
.home__promos1 {
    grid-area: promos1;
}
.home__promos2 {
    grid-area: promos2;
}

//----------------------------------------
// Featured stories
//----------------------------------------

.home__featured-stories {
    @include breakpoint-min(xs) {
        border-bottom: 1px solid var(--cool-10);
        @include set-light-dark(border-color, var(--cool-10), var(--cool-80));
        display: grid;
        grid-gap: var(--gutter);
        grid-template-areas:
            "f1 f1"
            "f2 f2"
            "f3 f3";
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: var(--gutter);
    }
    @include breakpoint-min(lg) {
        border-bottom: none;
        grid-template-areas:
            "f1 f1 f1 f1 f1 f1 f1 f1"
            "f2 f2 f2 f2 f2 f3 f3 f3"
            "f2 f2 f2 f2 f2 f3 f3 f3";
        grid-template-columns: repeat(8, 1fr);
        padding-bottom: 0;
    }
    @include breakpoint-min(xl) {
        grid-template-areas:
            "f1 f1 f1 f1 f1 f1 f1 f1 f1"
            "f2 f2 f2 f2 f2 f3 f3 f3 f3"
            "f2 f2 f2 f2 f2 f3 f3 f3 f3";
        grid-template-columns: repeat(9, 1fr);
    }

    // F1

    > *:nth-child(1) {
        @include set-light-dark(border-color, var(--cool-10), var(--cool-80));
        border-bottom: 1px solid var(--cool-10);
        grid-area: f1;
        padding-bottom: var(--gutter);
        @include breakpoint-min(xl) {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
        .story-card__art,
        .lead-art {
            float: none;
            margin-bottom: 0;
            width: 100%;
            @include breakpoint-range(xs, xl) {
                margin-left: 0;
            }
            @include breakpoint-min(xl) {
                align-self: flex-start;
                flex: 2;
                order: 1;
                margin-left: var(--spacing0);
                max-width: colspan(6, 9);
            }
        }
        .story-card__description {
            margin-top: var(--spacing-1);
            text-align: center;
            @include breakpoint-min(xl) {
                flex: 1;
                margin-top: 0;
            }
        }
        .story-card__hed {
            font-size: var(--scale3);
            font-weight: 900;
            line-height: var(--line-height-2);
            @include breakpoint-min(sm) {
                font-size: var(--scale5);
            }
            @include breakpoint-min(lg) {
                font-size: var(--scale4);
            }
        }
        .story-card__art + .story-card__description,
        .lead-art + .story-card__description {
            text-align: left;
            .story-card__hed {
                font-size: var(--scale2);
                @include breakpoint-min(sm) {
                    font-size: var(--scale4);
                }
                @include breakpoint-min(lg) {
                    font-size: var(--scale3);
                }
            }
        }
    }

    // F2 and F3

    > *:nth-child(2) {
        grid-area: f2;
        .story-card__art,
        .lead-art {
            @include breakpoint-range(xs, md) {
                float: none;
                margin-left: 0;
                width: 100%;
            }
            @include breakpoint-min(md) {
                width: colspan(3, 8);
            }
            @include breakpoint-range(lg, xl) {
                float: none;
                margin-left: 0;
                width: 100%;
            }
            @include breakpoint-min(xl) {
                width: colspan(2, 5);
            }
        }
        .story-card__hed {
            @include breakpoint-min(sm) {
                font-size: var(--scale3);
                line-height: var(--line-height-2);
            }
            @include breakpoint-min(lg) {
                font-size: var(--scale2);
            }
        }
        .story-card__art + .story-card__description,
        .lead-art + .story-card__description {
            .story-card__hed {
                font-size: var(--scale2);
                line-height: var(--line-height-2);
                @include breakpoint-min(sm) {
                    font-size: var(--scale4);
                }
                @include breakpoint-min(lg) {
                    font-size: var(--scale0);
                }
            }
        }
    }

    // F4, F5, F6

    > *:nth-child(3) {
        border-top: 1px solid var(--cool-10);
        @include set-light-dark(border-color, var(--cool-10), var(--cool-80));
        grid-area: f3;
        padding-top: var(--gutter);

        @include breakpoint-min(lg) {
            border-left: 1px solid var(--cool-10);
            border-top: none;
            @include set-light-dark(
                border-color,
                var(--cool-10),
                var(--cool-80)
            );
            padding-left: var(--gutter);
            padding-top: 0;
        }

        .story-card__art,
        .lead-art {
            @include breakpoint-range(xs, md) {
                float: none;
                margin-left: 0;
                width: 100%;
            }
            @include breakpoint-min(md) {
                width: colspan(3, 8);
            }
            @include breakpoint-min(lg) {
                display: none;
            }
        }
        .story-card__hed {
            font-size: var(--scale2);
            line-height: var(--line-height-2);
            @include breakpoint-min(sm) {
                font-size: var(--scale4);
            }
            @include breakpoint-min(lg) {
                font-size: var(--scale0);
            }
        }
        .story-card + .story-card {
            .story-card__hed {
                @include breakpoint-min(lg) {
                    font-size: var(--scale-1);
                }
            }
            .story-card__dek {
                @include breakpoint-min(lg) {
                    display: none;
                }
            }
        }
    }

    > *:nth-child(2) .story-card + .story-card,
    > *:nth-child(3) .story-card + .story-card {
        @include breakpoint-max(lg) {
            @include set-light-dark(
                border-color,
                var(--cool-10),
                var(--cool-80)
            );
            border-top: 1px solid var(--cool-10);
            padding-top: var(--gutter);
            margin-top: var(--gutter);
        }

        @include breakpoint-min(lg) {
            margin-top: var(--spacing1);
            padding-left: 0;
        }
    }
}

//----------------------------------------
// Featured series
//----------------------------------------

.home__featured-series {
    @include breakpoint-min(xs) {
        border-bottom: 1px solid var(--cool-10);
        @include set-light-dark(border-color, var(--cool-10), var(--cool-80));
        padding-bottom: var(--gutter);
    }
    @include breakpoint-min(lg) {
        border-bottom: none;
        border-left: 1px solid var(--cool-10);
        @include set-light-dark(border-color, var(--cool-10), var(--cool-80));
        padding-bottom: 0;
        padding-left: var(--gutter);
    }
    .story-card {
        margin-top: var(--spacing0);
    }
    .story-card__hed {
        font-family: var(--fonts-sans);
        font-size: var(--scale-1);
        font-weight: normal;
    }
    .story-card__art,
    .lead-art {
        @include breakpoint-min(lg) {
            width: 3em;
        }
    }
    .home__featured-series-subhed {
        color: var(--color-text-hed);
        font-family: var(--fonts-sans);
        font-weight: 700;
        line-height: var(--line-height-2);
    }
    .home__featured-series-section {
        clear: both;
        @include breakpoint-min(xs) {
            border-top: 1px solid var(--cool-10);
            @include set-light-dark(
                border-color,
                var(--cool-10),
                var(--cool-80)
            );
            padding-top: var(--gutter);
        }

        &:first-child {
            @include breakpoint-min(lg) {
                border: none;
                padding-top: 0;
            }
            .story-card > * + * {
                margin-top: var(--spacing0);
            }
            .story-card__art,
            .lead-art {
                @include breakpoint-min(lg) {
                    float: none;
                    margin-left: 0;
                    margin-top: var(--spacing0);
                    width: 100%;
                }
            }
            .story-card__dek {
                @include breakpoint-min(lg) {
                    font-size: var(--scale-2);
                }
            }
        }
        &:last-child {
            @include breakpoint-min(xs) {
                margin-top: var(--gutter);
            }
            @include breakpoint-min(sm) {
                display: flex;
                flex-wrap: wrap;
            }
            @include breakpoint-min(lg) {
                display: block;
            }
            .home__featured-series-subhed {
                width: 100%;
            }
            .story-card {
                .story-card__series,
                .story-card__dek,
                .story-card__byline {
                    display: none;
                }
                @include breakpoint-min(sm) {
                    flex-grow: 1;
                    flex-shrink: 0;
                    width: 50%;
                    &:nth-of-type(odd) {
                        padding-right: var(--gutter);
                    }
                    &:nth-of-type(even) {
                        border-left: 1px solid var(--cool-10);
                        @include set-light-dark(
                            border-color,
                            var(--cool-10),
                            var(--cool-80)
                        );
                        padding-left: var(--gutter);
                    }
                }
                @include breakpoint-min(lg) {
                    width: 100%;
                    &:nth-of-type(odd) {
                        border: none;
                        padding: 0;
                    }
                    &:nth-of-type(even) {
                        border: none;
                        padding: 0;
                    }
                }
                &:nth-of-type(n + 3) {
                    @include breakpoint-range(sm, lg) {
                        margin-top: 0;
                        padding-top: var(--spacing0);
                    }
                }
            }
            .story-card__hed {
                @include breakpoint-range(sm, lg) {
                    margin-top: 0;
                }
            }
        }
    }
}

//----------------------------------------
// River
//----------------------------------------

.home__river {
    > * + * {
        border-top: 1px solid var(--cool-10);
        @include set-light-dark(border-color, var(--cool-10), var(--cool-80));
        margin-top: var(--spacing0);
        padding-top: var(--spacing0);
    }

    .browse-by-month {
        label {
            display: block;
            font-family: var(--fonts-sans);
        }
        select {
            width: 100%;
        }
    }
}

//----------------------------------------
// Promos 1
//----------------------------------------

.home__promos1 {
    @include breakpoint-max(sm) {
        border-top: 1px solid var(--cool-10);
        @include set-light-dark(border-color, var(--cool-10), var(--cool-80));
        padding-top: var(--gutter);
    }
    > * + * {
        display: block;
        margin-top: var(--gutter);
    }
    .pp-module-title {
        color: var(--color-text-hed);
        font-family: var(--fonts-sans);
        font-size: var(--scale-1);
        font-weight: 700;
        line-height: var(--line-height-2);
        margin-bottom: var(--spacing-2);
    }
    .module-content {
        p {
            font-family: var(--fonts-sans);
            font-size: var(--scale-2);
            line-height: var(--line-height-1);
        }
        a {
            color: inherit;
            &:focus,
            &:hover {
                color: var(--color-accent-50);
            }
        }
    }
    .links-social {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0.8rem;
        @include breakpoint-min(sm) {
            max-width: 10rem;
        }
        .btn {
            display: block;
            text-align: center;
            margin-right: var(--spacing-1);
            margin-top: 0;
            margin-bottom: var(--spacing-1);
            line-height: var(--scale3);
            color: var(--color-light-none);
            background-color: var(--color-neutral-10);
            padding: 0;
            .icon {
                width: 1rem;
                height: 1rem;
                vertical-align: top;
                margin-top: 0.34rem;
                svg {
                    width: 1rem;
                    height: 1rem;
                }
            }
        }
    }
    #sidebar-email-subscribe-module {
        display: none;
    }
    .module-donate {
        border-radius: 4px; // Echos .btn styles
        line-height: var(--line-height-2);
        overflow: hidden;
        text-decoration: none;
        img {
            @include breakpoint-range(xs, sm) {
                display: none;
            }
            background-size: cover;
        }
        .donate-content {
            @include set-light-dark(background, var(--cool-80), var(--cool-70));
            color: var(--white);
            font-family: var(--fonts-sans);
            font-size: var(--scale-1);
            padding: var(--spacing0);
            @include breakpoint-range(lg, xl) {
                padding: var(--spacing-1);
            }
        }
        .btn {
            background: var(--red-50);
            color: var(--white);
            font-size: var(--scale-1);
            margin-top: var(--spacing0);
            padding: var(--spacing-1);
            &:focus,
            &:hover {
                background: var(--red-40);
            }
        }
    }
    .module-awards {
        @include set-light-dark(background, var(--yellow-20), var(--yellow-70));
        border-radius: 4px; // Echoes .btn styles
        padding: var(--spacing0);
        @include breakpoint-range(lg, xl) {
            padding: var(--spacing-1);
        }
        .module-photo {
            float: right;
            margin: 0 0 var(--spacing--1) var(--spacing-1);
            width: 5em;
            @include breakpoint-min(sm) {
                float: none;
                margin: 0 auto var(--spacing-1) auto;
                max-width: 7em;
                width: 100%;
            }
        }
    }
}

//----------------------------------------
// Promos 2
//----------------------------------------

.home__promos2 {
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    line-height: var(--line-height-1);
    > *:not(:last-child) {
        margin-bottom: var(--gutter);
    }
    .ad {
        @include breakpoint-max(lg) {
            display: none;
        }
    }
    .site-promo-module a {
        @include set-light-dark(background, var(--cool-10), var(--cool-70));
        border-radius: 4px; // Echoes .btn styles
        color: inherit;
        display: block;
        overflow: hidden;
        text-decoration: none;
    }
    .site-text {
        font-size: var(--scale-2);
        padding: var(--scale-1);
    }
    .site-title,
    .pp-module-title {
        color: var(--color-text-hed);
        font-family: var(--fonts-sans);
        font-size: var(--scale-1);
        font-weight: 700;
        line-height: var(--line-height-2);
    }
    .brief {
        align-items: center;
        color: inherit;
        display: flex;
        justify-content: space-between;
        margin-top: var(--spacing0);
        text-decoration: none;
        &:focus,
        &:hover {
            color: var(--color-accent-50);
            text-decoration: underline;
        }
    }
    .brief-thumb {
        align-self: flex-start;
        flex: 0;
        margin-left: var(--spacing-1);
        order: 2;
        width: 5rem;
        @include breakpoint-range(md, lg) {
            display: none;
        }
    }
    .module-popular {
        .pp-module-title {
            margin-bottom: var(--spacing0);
        }
        .tab-buttons {
            display: flex;
            margin-bottom: -1px;
        }
        .hed-stories {
            background: transparent;
            border: 1px solid var(--cool-10);
            border-bottom: none;
            @include set-light-dark(
                border-color,
                var(--cool-10),
                var(--cool-80)
            );
            border-radius: 4px 4px 0 0; // Echoes .btn styles
            color: var(--color-text-hed);
            display: inline-block;
            font-size: inherit;
            flex: 0 1 auto;
            line-height: var(--line-height-2);
            margin-right: var(--spacing-2);
            padding: var(--spacing-1);
            text-align: left;
            &[aria-selected="true"] {
                background: var(--color-bg);
                cursor: default;
                font-weight: 700;
            }
        }
        .list-stories {
            border-top: 1px solid var(--cool-10);
            @include set-light-dark(
                border-color,
                var(--cool-10),
                var(--cool-80)
            );
            counter-reset: start-from 0;
            li {
                border-bottom: 1px solid var(--cool-10);
                @include set-light-dark(
                    border-color,
                    var(--cool-10),
                    var(--cool-80)
                );
                position: relative;
                &::before {
                    color: var(--color-accent-50);
                    counter-increment: start-from;
                    content: counter(start-from);
                    font-feature-settings: "lnum" 1;
                    font-weight: 700;
                    position: absolute;
                    top: var(--spacing0);
                }
            }
        }
        a {
            color: inherit;
            display: block;
            padding: var(--spacing0) 0 var(--spacing0) 1.25em;
            text-decoration: none;
            &:focus,
            &:hover {
                color: var(--color-accent-50);
            }
        }
    }
}

// Donation Promo
// - For Engaged Users

.promo-donate-1 {
    @include set-light-dark(
        background-color,
        var(--yellow-10),
        var(--yellow-90)
    );
    padding: var(--spacing0);

    @include breakpoint-max(md) {
        padding: var(--spacing1);
        margin-bottom: 2.5rem !important;
        margin-left: calc(var(--spacing1) * -1) !important;
    }
}

.promo-donate-1__thumb {
    border-radius: 50%;
    float: right;
    width: var(--spacing3);
    height: var(--spacing3);
    margin: calc(var(--spacing2) * -1) calc(var(--spacing2) * -1)
        var(--spacing-2) var(--spacing0);

    @include breakpoint-max(md) {
        margin: var(--spacing-2) -3rem 0 0;
    }
}

.promo-donate-1__text {
    font-family: var(--fonts-sans);
    font-size: var(--scale0);
    font-weight: 700;
    line-height: var(--line-height-2);

    @include breakpoint-max(md) {
        font-size: var(--scale1);
    }
}

.promo-donate-1__btn {
    @include set-light-dark(mix-blend-mode, multiply, lighten);
    margin: 0 calc(#{$spacing2} * -1);
    margin-top: var(--spacing0);

    @include breakpoint-max(md) {
        margin: var(--spacing0) 0 -2.5rem 0;
        width: colspan(2, 4);
    }
}

// Modifier: full
// - put elements in a row, increase spacing

.promo-donate-1--full {
    @include breakpoint-min(lg) {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: var(--spacing2);
    }

    .promo-donate-1__thumb {
        @include breakpoint-min(lg) {
            float: none;
            margin: calc(var(--spacing2) * -2);
            margin-right: 0;
        }
    }

    .promo-donate-1__text {
        @include breakpoint-min(lg) {
            margin: 0 var(--spacing2);
        }
    }

    .promo-donate-1__btn {
        @include breakpoint-min(lg) {
            height: auto;
            margin: 0 calc(var(--spacing3) * -1) 0 0;
            min-width: 8rem;
            width: auto;
        }
    }
}

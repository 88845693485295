/* Overall page layout for article */

.entry-type-article {
    .series-nav {
        margin-block: 3rem;
    }
}

@media print {
    .entry-type-article {
        .site-header--compact,
        .site-header--fixed {
            .button {
                display: none !important;
            }
        }

        .article-body {
            .size01,
            .size02,
            .size03,
            .size04,
            .size05 {
                break-inside: avoid;
                float: right;
                margin: 0 0 var(--spacing1) var(--spacing1);
                width: 33%;
            }

            .size06,
            .size07,
            .size08,
            .size09,
            .size10,
            .size11,
            .size12,
            .size13,
            .size14 {
                break-inside: avoid;
                margin: var(--spacing1) auto;
                width: 50%;
            }
        }
    }
}

// Intro Module

.intro-module {
    h4 {
        color: var(--color-text-hed);
        font-family: var(--fonts-hed);
        font-weight: 700;
        line-height: var(--line-height-1);
        text-wrap: balance;
    }
}

.intro-module--on-article-page {
    position: sticky;
    top: 4rem;
}

/* For somewhat annoying reasons, this is necessary in order for position: sticky to work below. In the future,
   consider removing this div and the featured series content on the homepage altogether. */
#home__intro-module {
    display: contents;
}

.intro-module--on-homepage {
    margin-top: 0 !important;

    @include breakpoint-min(lg) {
        position: sticky;
        top: 4.5rem;
    }
}

.intro-module__dek {
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    line-height: var(--line-height-1);

    @include breakpoint-min(lg) {
        font-size: var(--scale-2);
    }

    p {
        margin-top: var(--spacing-1);
    }
    a {
        color: var(--color-text-hed);
    }
}

.intro-module__container {
    display: grid;
    margin-top: var(--spacing1);
    margin-bottom: var(--spacing0);

    @include breakpoint-max(lg) {
        /* Display as horizontally scrolling list */
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        gap: var(--spacing-1);
        overflow-x: scroll;

        /* Hide scrollbar */
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none;
        }

        /* Make full width */
        margin-left: calc(-50vw + 50%);
        margin-right: calc(-50vw + 50%);
        max-width: 100vw;
        padding-left: var(--page-margin);
        padding-right: var(--page-margin);
        width: auto;
    }
}

.intro-module__card {
    padding: var(--spacing0);
    border: 1px solid var(--color-neutral-10);
    border-radius: 4px;

    img {
        margin-bottom: var(--spacing0);
    }

    @include breakpoint-min(lg) {
        grid-column: 1;
        grid-row: 1;
        opacity: 0;
        visibility: hidden;
        transition: opacity 200ms ease-in-out, visibility 0s 200ms;
    }
}

.intro-module__nav {
    @include breakpoint-max(lg) {
        display: none;
    }

    text-align: center;

    a {
        display: inline-block;
        background-color: var(--color-neutral-20);
        height: 4px;
        border-radius: 2px;
        text-decoration: none;
        transition: width 0.2s;
        width: 4px;
    }

    .intro-module__nav--current {
        width: 40px;
        position: relative;

        /* Our progress bar */
        span {
            background-color: var(--color-neutral-70);
            width: 40px;
            height: 4px;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 2px;
            animation: progressBar 5s ease-in-out;
            animation-fill-mode: both;
        }

        @keyframes progressBar {
            0% {
                width: 4px;
            }
            100% {
                width: 40px;
            }
        }
    }
}

.intro-module__card--current {
    @include breakpoint-min(lg) {
        opacity: 1;
        visibility: visible;
        transition: opacity 200ms ease-in-out, visibility 0s 0s;
    }
}

.intro-module__art {
    @include breakpoint-max(lg) {
        width: 6rem;
        float: right;
        margin-left: var(--spacing-1);
        margin-bottom: var(--spacing-1);
    }
}

.intro-module__card-description {
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    line-height: var(--line-height-1);

    a {
        font-weight: 700;
        color: var(--color-text-hed);
    }

    @include breakpoint-max(lg) {
        width: 13rem;
    }
}

.intro-module__card-cta {
    font-family: var(--fonts-sans);
    font-size: var(--scale-2);
    font-weight: 700;
    color: var(--color-text-hed);
    line-height: 1.2em;

    a:first-of-type {
        border-bottom: 1px solid var(--color-neutral-10);
        padding-bottom: var(--spacing0);
    }

    a {
        padding: var(--spacing0) 0 0 0;

        display: grid;
        grid-template-columns: 1.2rem auto 0.8rem;
        align-items: top;
        gap: 7px;
        font-size: 12.5px;

        color: var(--color-text-hed);
        text-decoration: none;

        strong {
            text-decoration: underline;
        }

        .icon {
            --icon-size: 1.2rem;
        }

        .icon:last-of-type {
            --icon-size: 0.8rem;
            align-self: center;
            color: var(--color-neutral-20);
        }
    }
}

.intro-module__card-video-container {
    position: relative;
    cursor: pointer;
}

.intro-module__card-video-mute {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.intro-module__survey {
    color: var(--color-neutral-40);
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    line-height: var(--line-height-1);

    @include breakpoint-max(lg) {
        margin-top: var(--spacing0);
    }
    @include breakpoint-min(lg) {
        font-size: var(--scale-2);
        text-align: center;
    }

    a {
        color: inherit;
        display: inline-block;
    }
}

/* Temporary (variant-specific) additions to the intro module */

.intro-module__card--reporter {
    .intro-module__art--headshot {
        float: right;
        margin-left: var(--spacing-1);
        margin-bottom: var(--spacing-1);

        border-radius: 50%;
        height: 4rem;
        width: 4rem;
    }

    h5 {
        font-weight: 700;
    }

    p {
        margin: 0.5rem 0;

        @include breakpoint-max(lg) {
            font-size: var(--scale-2);
        }
    }

    ul {
        margin: 1rem 0;
        list-style-type: none;
    }

    .btn {
        --button-color: var(--color-neutral-10);
        padding: 0.4rem;
    }
}

.modal-republish__content {
    color: var(--cool-30);
    font-family: var(--fonts-sans);
}

.modal-republish__license {
    font-size: var(--scale-2);
}

.modal-republish__instructions {
    color: var(--cool-30);

    h3 {
        font-weight: 700;
    }

    hr {
        border-color: var(--cool-90);
    }

    ul {
        font-size: var(--scale-1);
        list-style-position: outside;
        list-style-type: disc;

        @include breakpoint-min(lg) {
            font-size: var(--scale-2);
        }
    }

    a {
        color: var(--blue-30);
    }
}

.modal-republish__section-copy {
    textarea {
        background-color: var(--cool-80);
        border: none;
        color: var(--cool-30);
        font-family: var(--fonts-mono);
        font-size: var(--scale-1);
        line-height: var(--line-height-1);
        padding: 1em;
        word-break: break-all;
    }
}

.modal-republish.-js-hide textarea {
    visibility: hidden; // Hide contents (including from browser search)
}

// Layout

.modal-republish {
    .modal-republish__content {
        display: flex;
        flex-direction: column;
        padding: 0 0 var(--spacing3) 0;
        margin: 0 auto;

        @include breakpoint-min(lg) {
            flex-direction: row;
            padding: 10vh 0;
            width: colspan(10, 12);
        }

        @include breakpoint-min(xl) {
            width: colspan(10, 14);
        }

        .modal-republish__instructions,
        .modal-republish__section-copy {
            flex: 1 1 0;
        }

        .modal-republish__instructions {
            padding: 0 0 var(--spacing1) 0;

            @include breakpoint-min(lg) {
                padding: 0 var(--spacing1) 0 0;
            }

            hr {
                margin: var(--spacing2) 0;
            }

            .modal-republish__intro,
            ul {
                margin: var(--spacing1) 0;
            }

            li {
                margin: var(--spacing-1) 0;
            }
        }

        .modal-republish__section-copy {
            .modal-republish__title {
                margin-bottom: var(--spacing0);
            }

            .-js-copy-textarea {
                height: 25vh;
                margin-bottom: var(--spacing-1);
                opacity: 1;
                position: static;
                width: 100%;
            }
        }
    }
}

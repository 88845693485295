// Newsletter Signup
// - For Returning Users

.promo-newsletter-see-all-2 {
    --size: 10rem;
}

// Newsletter Signup
// - For New Users

.promo-newsletter-see-all-2 {
    --color-accent-50: var(--pink-50);
    --button-size: #{$spacing2};
    font-family: var(--fonts-sans);
}

.promo-newsletter-see-all-2__max-width-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 36rem;
    padding: var(--spacing1);
    position: relative;

    @include breakpoint-max(sm) {
        // Fix issue with horizontal overscroll at smallest breakpoint, due to trapezoid skew increasing module width beyond margins.
        margin-left: calc((50vw - 50%) * -1);
        max-width: 100vw;
        overflow: hidden;
        width: 100vw;
    }
}

.promo-newsletter-see-all-2__bg {
    @include set-light-dark(border-color, var(--black), var(--gray-70));
    border-left: 2px solid;
    border-top: 2px solid;
    display: block;
    height: var(--spacing3);
    margin-bottom: calc(var(--spacing2) * -1);
    margin-left: calc(var(--spacing0) * -1);
    width: 100%;

    &:before {
        // Parallelogram shape
        @include set-light-dark(
            background-color,
            var(--warm-10),
            var(--warm-80)
        );
        content: "";
        height: calc(100% + var(--spacing0));
        position: absolute;
        transform: skew(-18deg) translateY(calc(var(--spacing2) * -1));
        width: calc(100% - var(--spacing0));
        z-index: -1;
    }
}

.promo-newsletter-see-all-2__content {
    margin-top: 0;
}

.promo-newsletter-see-all-2__explore {
    font-size: var(--scale-1);
    font-weight: 700;
    line-height: var(--line-height-1);
}

.promo-newsletter-see-all-2__btn-see-all {
    // flex-basis: 5rem;
    --color-btn: var(--pink-50);
    margin-top: var(--spacing0);
    width: 6rem;
}

// Modifier: small
// - hide logo, smaller type

.promo-newsletter-see-all-2--small {
    .promo-newsletter-see-all-2__explore {
        font-size: var(--scale-2);
    }
}

// Modifier: full
// - centers header, adds more space, bigger type

.promo-newsletter-see-all-2--full {
    .promo-newsletter-see-all-2__content {
        @include breakpoint-min(lg) {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin-bottom: 0;
        }
    }

    .promo-newsletter-see-all-2__explore {
        @include breakpoint-min(lg) {
            font-size: var(--scale0);
            margin-right: var(--scale3);
        }
    }

    .promo-newsletter-see-all-2__btn-see-all {
        @include breakpoint-min(lg) {
            margin-right: var(--spacing0);
            margin-top: 0;
            transform: translateX(50%);
            width: 10rem;
        }
    }
}

// Event Openers
//
// Three primary visual variations:
// - Standard
// - Donor
// - Engagement

.opener-event {
    @include full-width;
    --opener-page-margins: var(--spacing1);

    @include breakpoint-min(md) {
        --opener-page-margins: var(--spacing3);
    }

    color: var(--opener-textcolor);
    padding: var(--opener-page-margins);
    min-height: 100vh;

    @include breakpoint-max(lg) {
        padding-top: 4rem; // A bit more padding to allow images to clear
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include breakpoint-min(lg) {
        justify-content: center;
    }
}

.opener-event--standard {
    --opener-textcolor: var(--white);
    background-color: var(--blue-97);
}

.opener-event--donor {
    --opener-textcolor: var(--white);
    background-color: var(--black);
}

.opener-event--engagement {
    --opener-textcolor: var(--black);
    background-color: var(--yellow-50);

    // Override ShareTools svelte component with different colors
    // (The “right” way to do this would be to make a different ssr-include,
    // but that’s annoying/cumbersome to do. Given how infrequently the
    // engagement and donor event types are used, just override the colors.)
    .share-tools .button {
        --button-bg-color: var(--color-text-hed) !important;
        --button-color: var(--color-bg) !important;
    }
    .dark-mode-toggle {
        --button-color: var(--color-text-hed) !important;
    }
}

.opener-event--has-image {
    @include breakpoint-min(lg) {
        flex-direction: row-reverse;
        align-items: center;
    }
}

.opener-event__image-wrapper {
    @include breakpoint-max(lg) {
        margin-bottom: var(--spacing1);
    }
}

.text-light .opener-event .attribution {
    mix-blend-mode: screen;
}

.text-dark .opener-event .attribution {
    mix-blend-mode: multiply;
}

.opener-event__image--circular {
    border-radius: 50%;
}

.opener-event__image--screen {
    filter: grayscale(1);
    mix-blend-mode: screen;
}

.opener-event__bg {
    mix-blend-mode: screen;
    padding: 0 var(--opener-page-margins);
    pointer-events: none;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    > svg {
        fill: var(--cool-80);
        height: 100%;
        width: 100%;

        .opener-event--engagement & {
            fill: none;
        }
    }
}

.opener-event__text-wrapper {
    @include breakpoint-min(lg) {
        width: 66%;
    }

    .opener-event--has-image & {
        @include breakpoint-min(lg) {
            width: 100%;
            padding-right: var(--spacing2);
        }
    }
}

.opener-event__events-link {
    color: inherit;
    font-family: var(--fonts-sans);
    font-size: var(--scale0);
    font-weight: 700;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.opener-event__hed {
    font-family: var(--fonts-sans);
    font-size: var(--scale3);
    font-weight: 700;
    line-height: var(--line-height-2);
}

.opener-event__dek {
    font-family: var(--fonts-sans);
    font-size: var(--scale0);
    line-height: var(--line-height-1);
    margin-top: var(--spacing-1);
}

.event-date,
.event-time,
.event-location {
    font-family: var(--fonts-sans);
    font-size: var(--scale0);
    line-height: var(--line-height-1);
}

// Body Block: Image

// .bb-image {
// }

// Put the attribution on the side at large breakpoints
// Supports size08 only

.bb-image--sidecaption {
    @include breakpoint-min(lg) {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    &.size08 {
        @include breakpoint-min(lg) {
            img {
                width: colspan(6, 8);
            }

            .attribution {
                @include set-light-dark(
                    border-color,
                    var(--color-accent-30),
                    var(--color-accent-70)
                );
                border-top: var(--spacing-2) solid;
                padding-top: 1rem;
                margin: 0 colspan(-1, 8) colspan(g, 8) colspan(g, 8);
                width: colspan(3 + g, 8);
            }
        }
    }
}

.pagination {
    --pagination-number-size: 1.5rem;
    --pagination-arrow-size: 2rem;
}

.pagination__number,
.pagination__truncation {
    color: var(--color-neutral-70);
    display: block;
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    font-weight: 400;
    line-height: var(--pagination-number-size);
    height: var(--pagination-number-size);
    width: var(--pagination-number-size);
    text-align: center;
}

.pagination__number {
    background-color: transparent;
    border-radius: 50%;
    text-decoration: none;
    transition: all 0.15s linear;
}

.pagination__number--current {
    background-color: var(--color-accent-50);
    color: var(--white);
    font-weight: 700;
}

.pagination__number:not(.pagination__number--current):hover {
    background-color: var(--color-neutral-10);
}

.pagination__arrow {
    height: var(--pagination-arrow-size);
    width: var(--pagination-arrow-size);
}

// Block: Pull Quote

.bb-pull-quote {
    @include set-light-dark(
        color,
        var(--color-accent-70),
        var(--color-accent-40)
    );
    font-family: var(--fonts-sans);
}

.bb-pull-quote__text {
    font-family: var(--fonts-sans);
    font-size: var(--scale2);
    font-weight: 700;
    line-height: var(--line-height-2);
}

.bb-pull-quote__text::before {
    background-color: var(--color-accent-30);
    color: var(--color-bg);
    outline: 8px solid;
    border-radius: 50%;
    content: "“";
    display: block;
    font-family: var(--fonts-hed);
    float: left;
    font-size: var(--scale5);
    font-weight: 900;
    height: 3rem;
    line-height: 4rem;
    margin-right: 0.5rem;
    outline-color: var(--color-bg);
    text-align: center;
    width: 3rem;
    z-index: -1;

    @include breakpoint-min(md) {
        margin-left: -1.5rem;
        margin-top: calc(-2.5rem - 2px);
    }
}

.bb-pull-quote__source {
    font-size: var(--scale-2);
    line-height: var(--line-height-1);
    margin-top: 1em;
}

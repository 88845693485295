// Place page

//----------------------------------------
// SUBNAV
//----------------------------------------

.home-places {
    .site-header--full .section-links,
    .site-header--full .newsletter-signup,
    .site-header--full__divider {
        display: none;
    }

    .subnav {
        margin-top: -1px;

        @include breakpoint-min(lg) {
            margin-top: -2rem;
        }
    }
}

.place {
    @include set-light-dark(--border-color, var(--cool-10), var(--cool-70));
    --border: 1px solid var(--border-color);
    margin: var(--spacing1) 0;
    @include breakpoint-min(sm) {
        display: grid;
        grid-gap: $gut;
        grid-template-areas:
            "f1 f1 f1"
            "intro intro intro"
            "features features features"
            "series series series"
            "river river credits";
        grid-template-columns: repeat(3, 1fr);
    }
    @include breakpoint-min(md) {
        grid-gap: $gut;
        grid-template-areas:
            "f1 f1 f1 f1 f1 f1 f1 f1"
            "intro intro intro features features features features features"
            "series series series series series series series series"
            "river river river river river credits credits credits";
        grid-template-columns: repeat(8, 1fr);
        margin: 0;
    }
    @include breakpoint-min(lg) {
        grid-gap: $gut;
        grid-template-areas:
            "f1 f1 f1 f1 f1 f1 f1 f1 f1 f1 f1 f1"
            "intro intro intro intro features features features features features features features features"
            "series series series series series series series series series series series series"
            "river river river river river river river river credits credits credits credits";
        grid-template-columns: repeat(12, 1fr);
    }
    @include breakpoint-min(xl) {
        grid-gap: $gut;
        padding: 0 colspan(1 + g, 14);
    }
    a {
        color: inherit;
        text-decoration: none;
        &:focus,
        &:hover {
            color: var(--color-accent-70);
            text-decoration: underline;
            text-decoration-color: var(--color-accent-70);
            text-decoration-thickness: 0.1em;
            text-underline-offset: 0.1em;
        }
    }
    p:not(.place__byline):not(.place__series-hed) a {
        color: var(--color-accent-70);
        text-decoration: underline;
        text-decoration-color: var(--color-accent-30);
        text-decoration-thickness: 0.1em;
        text-underline-offset: 0.1em;

        &:focus,
        &:hover {
            text-decoration-color: currentColor;
        }
    }
    img {
        background: var(--gray-10);
    }
    .timestamp {
        white-space: nowrap;
    }
}

.place__series-hed {
    font-family: var(--fonts-sans);
    font-size: var(--scale-2);
    font-weight: 700;
    text-transform: uppercase;
}

a.place__cta-link {
    @include set-light-dark(background, var(--cool-10), var(--cool-80));
    border-radius: 0.2em;
    display: inline-block;
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    margin-top: var(--spacing0);
    padding: var(--spacing-2) var(--spacing-1);
    &:focus,
    &:hover {
        @include set-light-dark(
            background,
            var(--color-accent-50),
            var(--color-accent-50)
        );
        @include set-light-dark(color, white, white);
        text-decoration: none;
    }
}

// Place F1

.place__f1 {
    @include breakpoint-min(xs) {
        grid-area: f1;
    }
    @include breakpoint-min(md) {
        align-items: center;
        border-bottom: var(--border);
        display: flex;
        padding-bottom: $gut;
    }
}
.place__f1-art {
    align-self: flex-start;
    display: block;
    @include breakpoint-min(sm) {
        float: right;
        margin-left: var(--spacing0);
        width: colspan(3, 6);
    }
    @include breakpoint-min(md) {
        flex-shrink: 0;
        order: 2;
        width: colspan(4, 8);
    }
    @include breakpoint-min(lg) {
        width: colspan(7, 12);
    }
}
.place__f1-info {
    .place__f1-art + & {
        @include breakpoint-max(sm) {
            margin-top: var(--scale-1);
        }
    }
    > * + * {
        margin-top: var(--spacing-1);
    }
}
.place__f1-hed {
    color: var(--color-text-hed);
    font-family: var(--fonts-hed);
    font-size: var(--scale2);
    font-weight: 900;
    line-height: var(--line-height-2);
    @include breakpoint-min(lg) {
        font-size: var(--scale3);
    }
    .place__series-hed + & {
        margin-top: var(--spacing-2);
    }
}
.place__f1-dek {
    font-family: var(--fonts-sans);
    line-height: var(--line-height-1);
}
.place__f1-byline {
    color: var(--cool-50);
    font-family: var(--fonts-sans);
    font-size: var(--scale-2);
    line-height: var(--line-height-1);
}

// Place intro

.place__intro {
    border-top: var(--border);
    grid-area: intro;
    margin-top: var(--spacing1);
    padding-top: var(--spacing0);
    @include breakpoint-min(md) {
        border-top: none;
        border-right: var(--border);
        margin-top: 0;
        padding-right: $gut;
        padding-top: 0;
    }
}
.place__intro-hed,
.place__newsletter-hed {
    color: var(--color-text-hed);
    font-family: var(--fonts-sans);
    font-size: var(--scale1);
    font-weight: 700;
    line-height: var(--line-height-2);
    margin-bottom: var(--spacing-2);
}
.place__newsletter {
    border-top: var(--border);
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    line-height: var(--line-height-1);
    margin-top: var(--spacing1);
    padding-top: var(--spacing0);
    @include breakpoint-min(md) {
        margin-top: var(--spacing2);
        padding-top: var(--spacing2);
    }
}
// .place__newsletter-hed {
//     color: var(--color-text-hed);
//     font-size: var(--scale0);
// }
.place__newsletter-form-wrap {
    border: var(--border);
    @include set-light-dark(border-color, var(--cool-80), var(--cool-20));
    border-radius: 0.2em;
    font-size: var(--scale0);
    margin-top: var(--spacing0);
    max-width: 30em;
    padding: var(--spacing-2) var(--spacing-1);
    .newsletter-signup__input-text::placeholder {
        font-size: var(--scale-1);
    }
}

// Place features

.place__feature-group {
    border-top: var(--border);
    margin-top: var(--spacing1);
    padding-top: var(--spacing0);
    @include breakpoint-min(xs) {
        display: grid;
        grid-area: features;
        grid-gap: $gut;
        grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint-min(md) {
        border-top: none;
        display: block;
        margin-top: 0;
        padding-top: 0;
    }
}
.place__feature {
    clear: both;
    @include clearfix;
    @include breakpoint-min(md) {
        > * {
            margin-bottom: var(--spacing0);
        }
        &:last-child > .place__feature-info {
            margin-bottom: 0;
        }
    }
}
.place__feature:first-child {
    @include breakpoint-min(xs) {
        grid-column: auto / span 2;
    }
    @include breakpoint-min(sm) {
        border-right: var(--border);
        grid-column: auto / span 1;
        grid-row: auto / span 2;
        padding-right: $gut;
    }
    @include breakpoint-min(md) {
        border-right: none;
        padding-right: 0;
    }
}
.place__feature:nth-child(n + 2) {
}
.place__feature-art {
    display: block;
    @include breakpoint-range(sm, md) {
        .place__feature:nth-child(n + 2) & {
            display: none;
        }
    }
    @include breakpoint-min(md) {
        float: right;
        margin-left: var(--scale0);
        width: colspan(2, 5);
    }
    @include breakpoint-min(lg) {
        width: colspan(3, 8);
    }
}
.place__feature-info {
    .place__feature-art + & {
        margin-top: var(--spacing-1);
        @include breakpoint-min(md) {
            margin-top: 0;
        }
    }
    .place__feature:nth-child(n + 2) .place__feature-art + & {
        @include breakpoint-min(sm) {
            margin-top: 0;
        }
    }
    > * + * {
        margin-top: var(--spacing-1);
    }
}
.place__feature-hed {
    color: var(--color-text-hed);
    font-family: var(--fonts-hed);
    font-weight: 700;
    line-height: var(--line-height-1);
    .place__feature:first-child & {
        font-size: var(--scale1);
    }
    @include breakpoint-min(md) {
        font-size: var(--scale1);
    }
    .place__series-hed + & {
        margin-top: var(--spacing-2);
    }
}
.place__feature-dek {
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    line-height: var(--line-height-1);
}
.place__feature-byline {
    color: var(--cool-50);
    font-family: var(--fonts-sans);
    font-size: var(--scale-2);
    line-height: var(--line-height-1);
}

// Place staff/partners

.place__credits.place__credits {
    border-top: var(--border);
    font-family: var(--fonts-sans);
    margin-top: var(--spacing1);
    grid-area: credits;
    padding-top: var(--spacing0);
    @include breakpoint-min(sm) {
        border-top: none;
        border-left: var(--border);
        margin-top: 0;
        padding-left: $gut;
        padding-top: 0;
    }
    @include breakpoint-min(md) {
        padding-left: $gut;
    }
    p {
        font-size: var(--scale-1);
        line-height: var(--line-height-1);
    }
}
.place__staff-hed,
.place__partners-hed {
    color: var(--color-text-hed);
    font-size: var(--scale1);
    font-weight: 700;
    line-height: var(--line-height-2);
    margin-bottom: var(--spacing-1);
}
.place__staff-title {
    color: var(--color-text-hed);
    font-size: var(--scale-1);
    font-weight: 700;
    margin-top: var(--scale0);
    text-transform: uppercase;
}
.place__partners {
    @include breakpoint-min(xs) {
        border-top: var(--border);
        margin-top: $gut;
        padding-top: $gut;
    }
    @include breakpoint-min(md) {
        margin-top: $gut;
        padding-top: $gut;
    }
}
.place__partner-name {
    line-height: var(--line-height-2);
    margin-top: var(--scale0);
}
.place__partner-location {
    font-size: var(--scale-2);
}

// Place feaured series

.place__series-group {
    grid-area: series;
    @include breakpoint-min(sm) {
        border-top: var(--border);
    }
}

// Place river

.place__story-group.place__story-group {
    border-top: var(--border);
    grid-area: river;
    margin-top: var(--spacing1);
    padding-top: var(--spacing0);
    @include breakpoint-min(sm) {
        border: none;
        margin-top: 0;
        padding-top: 0;
    }
}
.place__story-card {
    clear: both;
    @include clearfix;
    > * {
        margin-bottom: var(--spacing0);
    }
    &:last-child > .place__story-card-info {
        margin-bottom: 0;
    }
}
.place__story-card-art {
    @include breakpoint-min(xs) {
        display: block;
        float: right;
        margin-left: var(--spacing0);
        width: colspan(1, 4);
    }
    @include breakpoint-min(sm) {
        width: colspan(1, 4);
    }
    @include breakpoint-min(md) {
        width: colspan(1, 5);
    }
    @include breakpoint-min(lg) {
        width: colspan(2, 8);
    }
}
.place__story-card-info {
    > * + * {
        margin-top: var(--spacing-1);
    }
}
.place__story-card-hed {
    color: var(--color-text-hed);
    font-family: var(--fonts-hed);
    font-weight: 700;
    line-height: var(--line-height-1);
}
.place__story-card-dek {
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    line-height: var(--line-height-1);
}
.place__story-card-byline {
    color: var(--cool-50);
    font-family: var(--fonts-sans);
    font-size: var(--scale-2);
    line-height: var(--line-height-1);
}

.fundraising-letter-inline {
    border-width: 6px;
    border-style: solid;
    // @include set-light-dark(border-color, var(--orange-30), var(--orange-70));
    border-image: linear-gradient(
            to top right,
            var(--orange-50),
            var(--pink-50),
            var(--cobalt-50)
        )
        1;
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    margin-top: var(--spacing1);
    margin-bottom: var(--spacing0);
    padding: var(--spacing0);

    @include breakpoint-min(md) {
        padding: var(--spacing1);
    }

    @include breakpoint-min(lg) {
        padding: var(--spacing2);
    }

    .pp-module-title {
        font-size: var(--scale0);
        font-weight: 700;
        background-color: var(--pink-50);
        background-image: linear-gradient(
            to right,
            var(--pink-50),
            var(--cobalt-40)
        );

        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
    }

    .module-content > * {
        margin-top: var(--spacing0);
    }

    a {
        color: inherit;
    }

    a.btn.btn-urgent {
        // background: linear-gradient(
        //     to right,
        //     var(--yellow-50),
        //     var(--turquoise-50)
        // );
        background: var(--red-50);
        color: white;
        display: inline-block;
        font-size: var(--scale0);
    }
}

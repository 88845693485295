.bb--border-top {
    @include set-light-dark(
        border-color,
        var(--color-accent-30),
        var(--color-accent-30)
    ); // We use the mixin rather than setting the property directly to match specificity
    padding-top: 1rem;
    border-top: var(--spacing-2) solid;
}

.bb--chunky-border-top {
    &::before {
        @include set-light-dark(
            background-color,
            var(--color-accent-30),
            var(--color-accent-30)
        ); // We use the mixin rather than setting the property directly to match specificity
        content: "";
        display: block;
        height: var(--spacing-2);
        margin-bottom: 1rem;
        width: colspan(3, 8);
    }
}

.bb--border-bottom {
    @include set-light-dark(
        border-color,
        var(--color-accent-30),
        var(--color-accent-30)
    ); // We use the mixin rather than setting the property directly to match specificity
    border-bottom: 1px solid;
    padding-bottom: 1rem;
}

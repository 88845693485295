.placeholder {
    @include set-light-dark(background-color, var(--gray-10), var(--gray-80));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.placeholder__text {
    display: block;
    font-family: var(--fonts-sans);
    font-weight: 700;
    text-transform: uppercase;
    color: var(--color-text-hed);
}

.bb-canned-promo-partnership-electionland__logo svg {
    display: block;
    margin: 0 auto 1rem;
    max-width: 10rem;
}

.bb-canned-promo-partnership-texastribune__logo {
    display: block;
    margin: 0 auto 1rem;
    max-width: 25rem;
}

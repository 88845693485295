.site-header,
.place {
    // More tightly scope .newsletter-signup styles to avoid conflict with
    // SiteFooter svelte component. Eventually we can replace this with the
    // NewsletterSignup component and remove this file entirely.

    .newsletter-signup {
        position: relative;
    }

    .newsletter-signup__input-wrap {
        align-items: center;
        display: flex;
        opacity: 1;
        transition: opacity 0.2s;
    }

    .-js-is-successful .newsletter-signup__input-wrap {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
    }

    .newsletter-signup__feedback {
        @include set-light-dark(background-color, var(--black), var(--cool-30));
        border-radius: 4px;
        color: var(--color-bg);
        font-size: var(--scale-2);
        font-weight: 700;
        left: 50%;
        padding: var(--spacing-1);
        position: absolute;
        text-align: center;
        top: calc(-1 * var(--spacing-2));
        transform: translate(-50%, -100%);
        margin: 0 auto;
        width: 80%;

        ::before {
            --tail-size: 0.75rem;
            @include set-light-dark(
                background-color,
                var(--black),
                var(--cool-30)
            );
            content: "";
            display: block;
            position: absolute;
            transform: rotate(45deg);
            width: var(--tail-size);
            height: var(--tail-size);
            bottom: calc(-1 / 2 * var(--tail-size));
            left: calc(50% - var(--tail-size) / 2);
        }

        display: none;
    }

    .-js-has-error .newsletter-signup__feedback {
        display: block;
    }

    .newsletter-signup__post-signup-prompt {
        color: inherit;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        transition: opacity 0.2s;

        // Vertically center inside container
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .-js-is-successful .newsletter-signup__post-signup-prompt {
        opacity: 1;
        pointer-events: auto;
        visibility: visible;
    }

    .newsletter-signup__input-text {
        border: none;
        color: inherit;
        flex-grow: 1;
        margin-left: var(--spacing-1);
        padding: 0.4em 0;

        &::placeholder {
            color: inherit;
            font-size: var(--scale-2);
        }

        &:focus::placeholder {
            color: transparent;
        }
    }

    .newsletter-signup__submit {
        @include set-light-dark(background, transparent, transparent);
        @include set-light-dark(color, inherit, inherit);
        border: none;
        border-radius: 50%;
        font-size: var(--scale3);
        height: 1em;
        overflow: hidden;
        padding: 0;
    }
}

.people-listing-header__portrait {
    border-radius: 50%;
    width: 6rem;
}

.people-listing-header__hed {
    color: var(--color-text-hed);
    font-family: var(--fonts-hed);
    font-size: var(--scale1);
    font-weight: 700;
}

.people-listing-header__bio-short {
    color: var(--color-neutral-50);
    font-family: var(--fonts-sans);
    font-size: var(--scale0);
    font-weight: 400;
    line-height: var(--line-height-1);
    text-align: center;
    text-wrap: balance;

    a {
        color: inherit;
        text-decoration: underline;
    }
}

.people-listing-bio {
    margin-top: var(--spacing2);
}

.people-listing-bio__text {
    > * + * {
        margin-top: var(--spacing0);
    }

    > p {
        color: var(--color-text-body);
        font-family: var(--fonts-sans);
        font-size: var(--scale0);
        font-weight: 400;
        line-height: var(--line-height-1);

        @include breakpoint-min(lg) {
            font-size: var(--scale-1);
        }
    }

    a {
        color: inherit;
    }

    > h3,
    > h4,
    > h5,
    > h6 {
        color: var(--color-text-hed);
        font-family: var(--fonts-sans);
        font-weight: 700;
        margin-top: var(--spacing1);

        a {
            color: var(--color-text-hed);

            &:hover,
            &:focus {
                border-bottom: none;
            }
        }
    }

    > h3 {
        font-size: var(--scale0);
    }

    > h4 {
        font-size: var(--scale-1);
    }

    > h5,
    > h6 {
        font-size: var(--scale-2);
    }
}

.people-listing-contact {
    border-top: 1px solid var(--color-neutral-10);
    border-bottom: 1px solid var(--color-neutral-10);
    margin-top: var(--spacing2);
    padding: var(--spacing-1) 0;

    @include breakpoint-max(lg) {
        @include full-width;
        padding: var(--spacing-1) var(--page-margin);
    }
}

.people-listing-contact__hed {
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    font-weight: 700;
    line-height: 1;
}

.people-listing-contact__prompt {
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    font-weight: 400;
    line-height: var(--line-height-1);
    margin-bottom: 0.3rem;
    margin-top: var(--spacing-1);
}

.entry-type-people-listing:not(.entry-type-people-listing--no-stories) {
    .people-listing-sidebar {
        @include breakpoint-max(lg) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        @include breakpoint-min(lg) {
            padding-top: 5rem;
            padding-bottom: 5rem;
            width: colspan(5, 12);

            border-right: 1px solid var(--color-neutral-10);
            padding-right: var(--spacing3);

            // height: 100vh;
            // overflow: scroll;
            // position: sticky;
            // top: 0;
        }
        @include breakpoint-min(xl) {
            width: colspan(5, 14);
        }
    }

    .people-listing-stories {
        @include breakpoint-min(lg) {
            padding-top: 5rem;
            padding-bottom: 5rem;
            padding-left: var(--spacing2);
            width: colspan(7, 12);
        }
        @include breakpoint-min(xl) {
            width: colspan(9, 14);
        }
    }

    .people-listing-featured-stories {
        .story-card {
            @include breakpoint-min(lg) {
                display: flex;
                flex-direction: row-reverse;
                gap: var(--spacing0);
            }
        }

        .story-card {
            border-bottom: 1px solid var(--color-neutral-10);
            margin-bottom: var(--spacing1);
            padding-bottom: var(--spacing1);
        }

        .story-card .lead-art {
            @include breakpoint-max(lg) {
                float: none;
                margin: 0 0 1rem 0;
                width: 100%;
            }
            @include breakpoint-min(lg) {
                flex-shrink: 0;
                float: none;
                margin: 0;
                width: colspan(3, 7);
            }
            @include breakpoint-min(xl) {
                width: colspan(5, 9);
            }
        }

        .story-card__hed {
            font-size: var(--scale2);
            line-height: var(--line-height-2);
        }

        .story-card__byline-author {
            display: none;
        }
    }

    .listing-river {
        .story-card + .story-card {
            border-top: 1px solid var(--color-neutral-10);
            margin-top: var(--spacing-1);
            padding-top: var(--spacing0);
        }

        .lead-art {
            @include breakpoint-min(lg) {
                width: colspan(2, 7);
            }
            @include breakpoint-min(xl) {
                width: colspan(2, 9);
            }
        }

        .story-card__dek {
            display: none;
        }

        .story-card__byline-author {
            display: none;
        }
    }
}

/* For non-reporting staff who likely will not have any stories */
.entry-type-people-listing--no-stories {
    .people-listing-header,
    .people-listing-bio,
    .people-listing-contact {
        margin-left: auto;
        margin-right: auto;

        @include breakpoint-min(lg) {
            width: colspan(8, 12);
        }
        @include breakpoint-min(xl) {
            width: colspan(8, 14);
        }
    }
    .people-listing-sidebar {
        width: 100%;

        @include breakpoint-max(lg) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        @include breakpoint-min(lg) {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
    }
}

.link-style--no-decoration {
    color: inherit;
    text-decoration: none;
}

.link-style--underline {
    color: inherit;
    text-decoration: underline;
}

.link-style--hover-underline {
    &:hover {
        text-decoration: underline;
    }
}

.link-style--hover-color {
    &:hover {
        color: var(--color-accent-50);
    }
}

.link-style--hover-underline-color {
    @extend .link-style--hover-color;
    @extend .link-style--hover-underline;
}

.link-style--hover-opacity {
    &:hover {
        opacity: 0.5;
    }
}

/* Scrollable Overflow */

.scrollable-overflow {
    max-width: 100%;
    overflow-x: auto; /* You may think you want overflow-x: scroll, but you don’t. With auto, no scrollbar appears if the content is not wide enough to need it. */
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    /* On touch screens, hide horizontal scrollbar (Webkit only) */
    @media (hover: none) {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    position: relative; /* For some reason, this is needed when used with a flexbox layout or the whole page will have a horizontal scrollbar. See https://stackoverflow.com/a/47377149 (based on testing, seems to be needed even if there isn’t a item with position: absolute.) Behavior seems inconsistent between Safari and Chrome. */
}

.scrollable-overflow--snap {
    scroll-snap-type: x mandatory;
}

.scrollable-overflow--snap__item {
    scroll-snap-align: start;
}

.scrollable-overflow--force-scrollbar {
    padding-bottom: 1rem !important;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 6px;
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 3px;
        background-color: var(--color-neutral-10);
        margin-left: var(--scrollbar-inset, 0);
        margin-right: var(--scrollbar-inset, 0);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: var(--color-neutral-30);
    }
}

/* Toggle class via JS. Use this as a hook to add custom styles, or use these default behaviors below: */
.js-scrollable-overflow--is-overflowing {
    /* Use on a flex container (like a .stack) where content is normally centered. This will force content to be left-aligned when content overflows. */
    &.scrollable-overflow--justify-start-when-overflowing,
    & .scrollable-overflow--justify-start-when-overflowing {
        justify-content: flex-start;
    }
}

.js-scrollable-overflow--mask-left {
    mask-image: linear-gradient(to right, transparent 0%, #fff 2rem);
}

.js-scrollable-overflow--mask-right {
    mask-image: linear-gradient(
        to right,
        #fff 0%,
        #fff calc(100% - 2rem),
        transparent 100%
    );
}

.js-scrollable-overflow--mask-left-and-right {
    mask-image: linear-gradient(
        to right,
        transparent 0%,
        #fff 2rem,
        #fff calc(100% - 2rem),
        transparent 100%
    );
}

@mixin scrollable-overflow--full {
    @include full-width;
    max-width: 100vw;

    padding-left: var(--page-margin);
    padding-right: var(--page-margin);

    .scrollable-overflow--snap__item {
        scroll-margin: var(--page-margin);
    }
}

/* Overflow container that should be full-width */
.scrollable-overflow--full {
    @include scrollable-overflow--full;
    --scrollbar-inset: var(--page-margin);

    &-below-xl {
        @include breakpoint-max(xl) {
            @include scrollable-overflow--full;
            --scrollbar-inset: var(--page-margin);
        }
    }

    &-below-lg {
        @include breakpoint-max(lg) {
            @include scrollable-overflow--full;
            --scrollbar-inset: var(--page-margin);
        }
    }

    &-below-md {
        @include breakpoint-max(md) {
            @include scrollable-overflow--full;
            --scrollbar-inset: var(--page-margin);
        }
    }

    &-below-sm {
        @include breakpoint-max(sm) {
            @include scrollable-overflow--full;
            --scrollbar-inset: var(--page-margin);
        }
    }
}

.scrollable-overflow__arrow {
    opacity: 1;
}

.scrollable-overflow__arrow--disabled {
    opacity: 0.1;

    &:hover {
        cursor: not-allowed;
        opacity: 0.1 !important;
    }
}

/* Overall page layout */

.entry-type-series-listing {
    .series-listing-header {
        @include full-width;
        display: grid;
        position: relative;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        place-items: center;

        margin-bottom: 4rem;
        background-color: var(--black);
    }

    .series-listing-header__art {
        grid-column: 1;
        grid-row: 1;
        align-self: stretch;
        opacity: 25%;

        &,
        & > img {
            /* Second selector for picture element */
            height: 100vh;
            object-fit: cover;
            object-position: 50% 50%;
            position: sticky;
            top: 0;
            z-index: 0;
        }
    }

    .series-listing-header__text {
        grid-column: 1;
        grid-row: 1/2;
        margin: 0 auto;
        padding: 6rem var(--page-margin) 4rem;
        z-index: 1;

        @include breakpoint-min(lg) {
            padding: var(--spacing3) 0;
        }

        @include breakpoint-min(xl) {
            width: colspan(12, 14);
        }
    }

    .series-listing-header__name {
        margin-top: 7rem;
    }

    .series-listing-header__topic + .series-listing-header__name {
        margin-top: 0;
    }

    .series-listing-header__topic + .series-listing-header__name::before,
    .series-listing-introduction::before {
        display: block;
        content: "";

        background-color: var(--color-neutral-70);
        height: 7rem;
        width: 1px;
        margin: 0 auto 1rem auto;
    }

    .series-nav {
        margin-top: -4rem; /* Align this to the bottom of .series-listing-header */
    }

    .series-listing-section-timeline {
        @include breakpoint-min(xl) {
            margin: 0 auto;
            width: colspan(10, 14);
        }
    }

    .series-listing-section-impact {
        @include breakpoint-min(xl) {
            margin: 0 auto;
            width: colspan(10, 14);
        }

        .story-card {
            min-width: 80%;
            width: 100%;

            @include breakpoint-min(lg) {
                min-width: colspan(3, 12);
            }

            @include breakpoint-min(xl) {
                min-width: colspan(3, 10);
            }
        }
    }
}

.series-listing-header__topic {
    color: var(--color-text-body);
    font-family: var(--fonts-sans);
    font-size: var(--scale0);
    font-weight: 700;
    line-height: var(--line-height-3);
    text-align: center;
}

/* Header */

.entry-type-series-listing {
    --site-header-color-bg: var(--black);
    --site-header-color-border: var(--gray-80);
    --site-header-color-text: var(--white);
    --site-header-color-button-bg: var(--black);
    --site-header-color-button-text: var(--white);
}

.series-listing-header__name {
    color: var(--color-text-hed);
    font-family: var(--fonts-hed);
    font-size: var(--scale5);
    font-weight: 900;
    line-height: var(--line-height-3);
    text-align: center;

    @include breakpoint-min(xl) {
        font-size: var(--scale7);
    }
}

.series-listing-header__tagline {
    color: var(--color-text-supp);
    font-family: var(--fonts-hed);
    font-size: var(--scale2);
    font-weight: 700;
    line-height: var(--line-height-2);
    text-align: center;
}

.series-listing-introduction {
    color: var(--color-text-body);
    font-family: var(--fonts-sans);
    font-size: var(--scale0);
    font-weight: 400;
    line-height: var(--line-height0);
    letter-spacing: -0.02rem;

    margin: 0 auto;

    @include breakpoint-min(lg) {
        width: colspan(8, 14);
    }

    @include breakpoint-min(xl) {
        width: colspan(10, 14);
    }

    a {
        color: inherit;
        text-decoration: underline;
    }

    /* Temp fix for the intro in https://www.propublica.org/series/state-of-denial */
    > * {
        margin: 1rem 0;
    }
    > ul {
        list-style-type: initial;
    }
    /* End temp fix */
}

/* Sections */

// .series-listing-section__hed {
//     border-bottom: 1px solid var(--color-neutral-10);
//     padding-bottom: var(--scale-2);

//     font-family: var(--fonts-sans);
//     font-size: var(--scale0);
//     font-weight: 700;
//     line-height: var(--line-height-3);

//     /* For horizontally-scrolling sections, pin the header on the left edge */
//     position: sticky;
//     left: 0;
// }

/* Featured Stories */

.series-listing-section-featured-stories {
    .story-card__series {
        display: none;
    }

    > .story-card--callout {
        text-align: center;

        .story-card__description {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .story-card--callout__author-photos {
            justify-content: center;
        }
    }

    @include breakpoint-max(lg) {
        gap: 1rem;

        > .story-card + .story-card {
            border-top: 1px solid var(--color-neutral-10);
            padding-top: 1rem;
        }

        > .story-card {
            .lead-art {
                float: none;
                margin-left: 0;
                width: 100%;
            }
            .story-card__hed {
                font-size: var(--scale2);
                line-height: var(--line-height-2);
            }
        }
    }
    @include breakpoint-min(lg) {
        gap: 4rem;

        margin: 0 auto;
        width: colspan(12, 12);

        > .story-card {
            display: flex;
            flex-direction: row-reverse;

            .lead-art {
                float: none;
                margin: 0 0 0 1rem;
                flex: 0 0 colspan(8, 12);
            }
            .story-card__hed {
                font-size: var(--scale2);
                line-height: var(--line-height-2);
            }
        }

        > .story-card:nth-of-type(even) {
            display: flex;
            flex-direction: row;

            .lead-art {
                float: none;
                margin: 0 1rem 0 0;
            }
        }

        > .story-card--callout {
            .story-card__hed {
                font-size: var(--scale4);
                max-width: 25ch;
            }
            .story-card__dek {
                max-width: 50ch;
            }
        }
    }
    @include breakpoint-min(xl) {
        width: colspan(10, 14);

        > .story-card {
            .lead-art {
                flex: 0 0 colspan(7, 10);
            }
        }
    }
}

/* Impact */

.series-listing-section-impact {
    .story-card__hed {
        font-size: var(--scale-1);
    }

    .lead-art,
    .story-card__series,
    .story-card__dek,
    .story-card__unit {
        display: none;
    }
}

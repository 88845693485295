//----------------------------------------
// Story Cards
// Baseline styles for all story cards
//----------------------------------------

.story-card {
    clear: both;

    a {
        color: inherit;
        text-decoration: none;
        &:focus,
        &:hover {
            color: var(--color-accent-50);
            text-decoration: underline;
        }
    }
}

.story-card__series {
    color: var(--color-text-hed);
    display: block;
    font-family: var(--fonts-sans);
    font-size: var(--scale-2);
    font-weight: 700;
    letter-spacing: 0.03em;
    text-transform: uppercase;
}

.story-card__art,
.story-card .lead-art {
    display: block;
    float: right;
    margin: 0 0 var(--spacing-1) var(--spacing-1);
    width: 5rem;
}

.story-card__description {
    > * + * {
        margin-top: var(--spacing-1);
    }
}

.story-card__hed {
    color: var(--color-text-hed);
    font-family: var(--fonts-hed);
    font-weight: 700;
    line-height: var(--line-height-1);

    .story-card__series + & {
        margin-top: var(--spacing-3);
    }
}

.story-card__dek {
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    line-height: var(--line-height-1);
}

.story-card__byline {
    @include set-light-dark(color, var(--cool-40), var(--cool-50));
    font-family: var(--fonts-sans);
    font-size: var(--scale-2);
    line-height: var(--line-height-1);
}

.story-card__unit {
    background-color: var(--color-neutral-80);
    color: var(--color-bg);
    display: inline-block;
    font-family: var(--fonts-sans);
    font-size: var(--scale-3);
    font-weight: 700;
    letter-spacing: 0.03em;
    padding: 0.2em 0.5em;
    text-decoration: none;
    text-transform: uppercase;
}

.story-card--callout__author-photos {
    margin-top: var(--spacing0);
}

.story-card--callout__author-photo-link {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    overflow: hidden;
}

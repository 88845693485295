/*
    Listing page styles

    Shared by:
    - topic pages
    - series pages
*/

/* Sections */

.listing-section__hed {
    border-bottom: 1px solid var(--color-neutral-10);
    padding-bottom: var(--scale-2);

    font-family: var(--fonts-sans);
    font-size: var(--scale0);
    font-weight: 700;
    line-height: var(--line-height-3);
    width: 100%;

    /* For horizontally-scrolling sections, pin the header on the left edge */
    // position: sticky;
    // left: 0;

    .icon {
        position: absolute;
        transform: translateX(0.2em);
    }
}

/* River */

.listing-river {
    gap: var(--spacing-2);

    > .story-river-item,
    > .pagination {
        border-top: 1px solid var(--color-neutral-10);

        @include breakpoint-max(lg) {
            margin-top: var(--spacing0);
            padding-top: var(--spacing1);
        }

        @include breakpoint-min(lg) {
            margin-top: var(--spacing-1);
            padding-top: var(--spacing0);
        }
    }
}

.listing-river__story-count {
    color: var(--color-neutral-30);
    font-family: var(--fonts-sans);
    font-size: var(--scale-1);
    font-weight: 600;
    text-align: center;
}

// Audm audio player iframe embeddings. The iframe is:
// - light by default
// - light/dark based on the user device's system preference for light/dark
// - light/dark if the user has set "force light/dark mode" on an article
//
// Important: force light/dark mode on an article takes precedence over the
// device's system preferences.
iframe#audm-player-embed {
    @media (prefers-color-scheme: dark) {
        filter: invert(1);
    }
}
html.force-light-mode iframe#audm-player-embed {
    filter: invert(0);
}
html.force-dark-mode iframe#audm-player-embed {
    filter: invert(1);
}

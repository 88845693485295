.topics-list {
    color: var(--color-text-hed);
    font-family: var(--fonts-sans);
    font-size: var(--scale-2);

    @include breakpoint-max(lg) {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 0 !important;
    }

    @include breakpoint-min(lg) {
        line-height: var(--line-height-1);
    }
}

.topics-list__label {
    @include breakpoint-max(lg) {
        margin-right: 0.2em;
    }
}

.topics-list__group {
    @include breakpoint-max(lg) {
        display: contents; // Allow .topics-list__topic items inside to wrap with .topics-list__label
    }
}

.topics-list__topic {
    @include breakpoint-max(lg) {
        // Add commas between topics when inline

        &::after {
            content: ",";
            display: inline;
            margin-left: -0.2em;
            margin-right: 0.2em;
        }

        &:last-child::after {
            content: "";
            margin: 0;
        }
    }
    @include breakpoint-min(lg) {
        margin-top: 0.5em;
    }
}

.topics-list__topic-link {
    color: var(--color-text-hed);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
